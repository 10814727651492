<template>
<!-- MODAL Text Alert -->
  <div id="textAlertModal"
  class="col-12 justify-content-center pe-6"
  style="padding: 15vh 20%">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">

      <div class="form-group col-12 p-3">
        <div class="col-12 tx-clomos-midtone">
          <div class="col-12 d-flex flex-column p-0 m-0">
            <span class="col-12 tx-bold ">
              {{ alertText }}
            </span>
            <span class="col-12">
              {{ alertTarget }}
            </span>
          </div>
          <div v-if="alertInput != undefined && (alertInput === 'date' || alertInput === 'datetime-local')" class="col-12 d-flex flex-column p-0 m-0">
            <span class="col-12 tx-bold ">
              {{dictionary['Fecha'][session.user.language]+": "}}
            </span>
            <input :type="alertInput" :value="alertValue" @change="(event)=>this.$emit('updateAlertValue',event.target.value)"
            class="form-control form-control-line ps-0 pe-0" style="color:#000!important">
          </div>
        </div>

        <div class="row">

          <div class="col-lg-6 col-md-6 col-12 mt-3">
  <!-- SUBMIT -->
            <button type="button" id="btnSubmitCreateNewEmplacement" class="btn btn-clomos w-100"
            @click="this.$emit('acceptAlert')">
            {{dictionary['Aceptar'][session.user.language]}}
            </button>
          </div>

          <div class="col-lg-6 col-md-6 col-12 mt-3">
  <!-- DISMISS -->
            <button type="button" id="btnSubmitCreateNewEmplacement" class="btn btn-clomos w-100"
            @click="this.$emit('dismissAlert')">
            {{dictionary['Cancelar'][session.user.language]}}
            </button>
          </div>

        </div>
      </div>

    </div>
  </div>

</template>

<script>
// Clomos
export default {
  name: "TextAlertModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    alertText: {
      type: String,
      requiered: true,
    },
    alertTarget: {
      type: String,
      requiered: true,
    },
    alertInput: {
      type: String,
      requiered: true,
    },
    alertValue: {
      type: String,
      requiered: true,
    }
  }
}
</script>

<style>
#textAlertModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>