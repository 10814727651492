<template>
<!-- SECTION Machines-->
  <div id="machinesSection" class="col-12 justify-content-center pe-3"
  :class="(visibility.sideMenu)?'section-container-sm' :'section-container-lg'">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">
  
    <!-- HEADER Machines -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100" >
        <div :class="(this.$route.params.currentDisplay==='machines')?'col-lg-6 col-md-12 col-12 justify-content-center':'col-12 justify-content-center'" style="display: inherit">
          <!--<ion-icon v-if="session.user.bookmark==='machines'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <h4 class="tx-left tx-clomos-white text-nowrap mb-2 ps-1 pt-2" style="height:40px;">

            <ion-icon name="cog-outline" class="tx-27 me-2 align-bottom"></ion-icon>
              <span> {{dictionary["Máquinas"][session.user.language] }} </span>
              <span v-if="this.$route.params.currentDisplay==='machines' || this.targetEmplacementId == ''"> 
                {{ ": "+currentCompany.name }} 
              </span>
              <span v-else-if="this.targetEmplacementData.label != undefined"> 
                {{ ": "+targetEmplacementData.label }} 
              </span>
          </h4>
        </div>

<!-- Selector de Sistema -->
        <div v-if="this.$route.params.currentDisplay==='machines'"
        class="col-lg-3 col-md-12 col-12 p-2 pb-1 justify-content-center">	
          <select
          :value="currentCompany.nifCif"
          class="form-control h-100 text-nowrap bg-white" 
          style="border: solid 2px white ; color: black;"
          @change="onCurrentCompanyChanged">
            <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
            <option v-for="company in availableCompanies" :key="company.nifCif"
            :v-if="company.nifCif != session.user.nifCif
            && company.relation != undefined
            && company.relation.referenceCompany === this.session.user.nifCif"
            :value="company.nifCif">
              {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
            </option>
          </select>
        </div>

        <div v-if="this.$route.params.currentDisplay==='machines'"
        class="col-lg-3 col-md-12 col-12 p-2 pb-1">	
          <button
          id="btnDisplayNewMachineModal"
          class="btn btn-clomos bg-white rounded p-1 inner-border fw-normal text-nowrap"
          style="width: 100%; height:100%; vertical-align: middle; border: solid 2px white"
          @click="toggleNewMachineModal">
              <ion-icon name="add-circle-outline" class="tx-clomos-dark me-2 tx-20" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
              <span class="ms-auto tx-clomos-dark">{{dictionary["Añadir máquina"][session.user.language]}}</span>
          </button>
        </div>
      </div>

      <div class="m-0 p-3 overflow-auto">
        <table id="machinesDataTable" class="display w-100 border-1 p-0 m-0 rounded-3">
          <thead class="bg-clomos-light">
            <tr>
              <th v-for="column in machinesColumns" :key="column.data"
              :width="column.width" class="tx-black fw-medium text-nowrap">
                {{dictionary[column.text][session.user.language]}}
              </th>
            </tr>
          </thead>
        </table>
      </div>

    </div>
  </div>

<!-- SECTION Machines -->
  <!-- <StatusComponent v-if="this.$route.params.currentDisplay==='machines'"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :visibility="visibility"
  :currentCompany="currentCompany"
  :requestedMachineId="targetMachineId"
  :dataTableOptions="dataTableOptions">
  </StatusComponent> -->

<!-- MODAL New Machine -->
  <NewMachineModal
  v-if="display.newMachineModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  v-on:toggleNewMachineModal="toggleNewMachineModal">
  </NewMachineModal> 

<!-- MODAL Edit Machine -->
  <EditMachineModal
  v-if="display.editMachineModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :machineId="targetMachineId"
  v-on:toggleEditMachineModal="toggleEditMachineModal">
  </EditMachineModal>

<!-- MODAL Delete Text Alert -->
  <TextAlertModal
  v-if="display.deleteMachineModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertText="dictionary['Máquina a eliminar'][session.user.language] + ': '"
  :alertTarget="targetMachineId"
  v-on:acceptAlert="onDeleteMachine"
  v-on:dismissAlert="toggleDeleteMachineModal">
  </TextAlertModal>

  <!-- SECTION Devices -->
  <BundlesComponent v-if="this.$route.params.currentDisplay==='machines'"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :visibility="visibility"
  :currentCompany="currentCompany"
  :targetMachineId="targetMachineId"
  :dataTableOptions="dataTableOptions">
  </BundlesComponent>
</template>

<script>
import $ from 'jquery';
import BundlesComponent from '@/components/bundles/BundlesComponent';
import NewMachineModal from '@/components/machines/NewMachineModal';
import EditMachineModal from '@/components/machines/EditMachineModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "MachinesComponent",
  components: { BundlesComponent, NewMachineModal,  EditMachineModal, TextAlertModal },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    targetEmplacementId: {
      type: String,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      if (this.$route.params.currentDisplay === 'machines' || this.targetEmplacementId == '') {
        await this.getMachinesDataByCompany();
        this.displayMachinesDataTable();
      }
      else {
        await this.getEmplacementData();
        await this.getMachinesDataByEmplacement();
        this.displayMachinesDataTable();
      }
      if (this.machineList[0] != undefined && this.targetMachineId === "")
        this.targetMachineId = this.machineList[0].id;
    },
    targetEmplacementId: async function() {
      if (this.$route.params.currentDisplay === 'machines' || this.targetEmplacementId == '') {
        await this.getMachinesDataByCompany();
        this.displayMachinesDataTable();
      }
      else {
        await this.getEmplacementData();
        await this.getMachinesDataByEmplacement();
        this.displayMachinesDataTable();
      }
      if (this.machineList[0] != undefined && this.targetMachineId === "")
        this.targetMachineId = this.machineList[0].id;
    }
  },
  data() {
    return {
      machinesColumns: [
        {text:"Identificador", data: "machineId", width: "25%",
          render: (data) => {
            return "<span data-id="+data+" class='btnTargetMachine btn tx-bold tx-clomos-midtone text-nowrap'>"+data+"</span>"
          }
        },
        {text:"Nombre", data: "machineLabel", width: "15%"},
        {text:"Tipo", data: "machineType", width: "10%"},
        {text:"Emplazamiento", data: "machineEmplacement", width: "10%"},
        {text:"Fecha de comunicación", data: "machineCommunicationDateTime", width: "10%",
          render: (data) => {
            if (data === "") return data
            else return this.$dayjs(new Date(data)).utc("z").local().tz(this.session.user.dateTimeZone).format(this.session.user.dateTimeFormat+" HH:mm:ss")
          }
        },
        {text:"Configuración", data: "machineId", width: "5%",
          render: (data) => {
            return "<ion-icon name='settings-outline' data-id="+data+" class='btnEditMachine btn tx-24 tx-clomos-midtone'></ion-icon>"
          }
        },
        {text:"Eliminar", data: "machineId", width: "5%",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id="+data+" class='btnDeleteMachine btn tx-24 tx-warning'></ion-icon>"
          }
        },
      ],
      targetEmplacementData: {},
      targetMachineId: this.$route.params.targetEntity,
      machineList: [],
      display: {
        newMachineModal: false,
        editMachineModal: false,
        linkMachineModal: false,
        deleteMachineModal: false,
      },
      linkAction: "link"
    }
  },
  emits: ["setCurrentCompany"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    displayMachinesDataTable() {
      $("#machinesDataTable").DataTable().clear()
      for (let i in this.machineList) {
        $("#machinesDataTable").DataTable().rows.add([{
          machineId: this.machineList[i].id, 
          machineEmplacement: this.machineList[i].parent, 
          machineLabel: this.machineList[i].label, 
          machineType: this.machineList[i].type, 
          machineStatus: this.machineList[i].status, 
          machineCommunicationDateTime: this.machineList[i].communicationDateTime, 
          machineVersion: this.machineList[i].version, 
        }]);
      }
      $("#machinesDataTable").DataTable().draw();
      this.setButtonFunctions();
     if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
    },
    async toggleNewMachineModal() {
      if (this.display.newMachineModal) {
        if (this.$route.params.currentDisplay === 'machines' || this.targetEmplacementId == '') {
          await this.getMachinesDataByCompany();
          this.displayMachinesDataTable();
        }
        else {
          await this.getEmplacementData();
          await this.getMachinesDataByEmplacement();
          this.displayMachinesDataTable();
        }
      }
      this.display.newMachineModal = !this.display.newMachineModal;
    },
    async toggleEditMachineModal() {
      if (this.display.editMachineModal) {
        if (this.$route.params.currentDisplay === 'machines' || this.targetEmplacementId == '') {
          await this.getMachinesDataByCompany();
          this.displayMachinesDataTable();
        }
        else {
          await this.getEmplacementData();
          await this.getMachinesDataByEmplacement();
          this.displayMachinesDataTable();
        }
      }
      this.display.editMachineModal = !this.display.editMachineModal;
    },
    async toggleLinkMachineModal() {
      if (this.display.linkMachineModal) {
        if (this.$route.params.currentDisplay === 'machines' || this.targetEmplacementId == '') {
          await this.getMachinesDataByCompany();
          this.displayMachinesDataTable();
        }
        else {

          await this.getEmplacementData();
          await this.getMachinesDataByEmplacement();
          this.displayMachinesDataTable();
        }
      }
      this.display.linkMachineModal = !this.display.linkMachineModal;
    },
    async toggleDeleteMachineModal() {
      if (this.display.deleteMachineModal) {
        if (this.$route.params.currentDisplay === 'machines' || this.targetEmplacementId == '') {
          await this.getMachinesDataByCompany();
          this.displayMachinesDataTable();
        }
        else {
          await this.getEmplacementData();
          await this.getMachinesDataByEmplacement();
          this.displayMachinesDataTable();
        }
        if (this.machineList[0] != undefined) this.targetMachineId = this.machineList[0].id;
      }
      this.display.deleteMachineModal = !this.display.deleteMachineModal;
    },
    // BUTTONS
    setButtonFunctions() {
      $(".btnTargetMachine").get().forEach(btn => {
        btn.addEventListener('click', this.onTargetMachine);
      });
      $(".btnEditMachine").get().forEach(btn => {
        btn.addEventListener('click', this.onEditMachine);
      });
      $(".btnLinkMachine").get().forEach(btn => {
        btn.addEventListener('click', this.onLinkMachine);
      });
      $(".btnUnlinkMachine").get().forEach(btn => {
        btn.addEventListener('click', this.onUnlinkMachine);
      });
      $(".btnDeleteMachine").get().forEach(btn => {
        btn.addEventListener('click', this.onDeleteMachine);
      });
    },
    // GET
    async getMachinesDataByCompany(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getMachineListByCompany(request);
      this.machineList = api.parseMachinesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.machineList);
    },
    async getMachinesDataByEmplacement(callback) {
      let request = {
        emplacementId: this.targetEmplacementId,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getMachineListByEmplacement(request);
      this.machineList = api.parseMachinesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.machineList);
    },
    async getEmplacementData(callback) {
      let response = await api.getEmplacementData(this.targetEmplacementId);
      this.targetEmplacementData = api.parseEmplacementFromResponse(response);
      if (callback && typeof callback === "function") callback(this.targetEmplacementData);
    },
    // TARGET 
    onTargetMachine(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetMachineId = event.target.getAttribute('data-id')
      this.$router.replace({params: {targetEntity: this.targetMachineId}})
    },
    // SET 
    onEditMachine(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetMachineId = event.target.getAttribute('data-id');
      this.$router.replace({params: {targetEntity: this.targetMachineId}})
      this.toggleEditMachineModal();
    },
    // LINK 
    onLinkMachine(event) {
      console.log('LINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetMachineId = event.target.getAttribute('data-id')
      this.$router.replace({params: {targetEntity: this.targetMachineId}})
      this.linkAction = "link"
      this.toggleLinkMachineModal();
    },
    // UNLINK 
    onUnlinkMachine(event) {
      console.log('UNLINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetMachineId = event.target.getAttribute('data-id')
      this.$router.replace({params: {targetEntity: this.targetMachineId}})
      this.linkAction = "unlink"
      this.toggleLinkMachineModal();
    },
    // DELETE
    async onDeleteMachine(event) {
      if (!this.display.deleteMachineModal) {
        console.log('DELETE WHERE ID:');
        console.log(this.targetMachineId);
        this.targetMachineId = event.target.getAttribute('data-id');
        this.$router.replace({params: {targetEntity: this.targetMachineId}})
      }
      else {
        let response = await api.deleteMachine(this.targetMachineId);
        console.log(response);
      }
      await this.toggleDeleteMachineModal();
    }
  },
  async created() {
    if (this.$route.params.currentDisplay === 'machines') {
      await this.getMachinesDataByCompany();
      this.displayMachinesDataTable();
    }
    else if (this.targetEmplacementId != ''){
      await this.getEmplacementData();
      await this.getMachinesDataByEmplacement();
      this.displayMachinesDataTable();
    }
    if (this.machineList[0] != undefined && this.targetMachineId === "")
      this.targetMachineId = this.machineList[0].id;
  },
  mounted() {
    $("#machinesDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.machinesColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
  }
}
</script>

<style>
.btnTargetMachine:hover, .btnLinkMachine:hover, .btnUnlinkMachine:hover, .btnEditMachine:hover, .btnDeleteMachine:hover {
  color: var(--main-dark);
}
</style>