<template>
<!-- MODAL New Emplacement -->
  <div id="newEmplacementModal"
  class="col-12 justify-content-center pe-6"
  style="padding: 15vh 20%">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">

<!-- HEAD New Emplacement -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100">
        <div class="col-10">
          <h4 class="tx-left tx-clomos-white mb-2 ps-1 pt-2">
            <ion-icon name="location-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary['Nuevo emplazamiento'][session.user.language]}}
          </h4>
        </div>

        <div class="col-2 m-0 p-0" style="display: flex; justify-content: end;"
        @click="$emit('toggleNewEmplacementModal')">	
          <ion-icon name="close" class="ms-auto tx-46 tx-white align-bottom"></ion-icon>
        </div>
      </div>

<!-- CONTENT New Emplacement -->
      <div class="col-12 p-0 w-100">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="card-body">

            <form class="form-material border-1 p-3 pt-0 overflow-hidden">
              <div class="container-fluid p-3">
                
                <div class="row">
                  
<!-- NOMBRE -->
                  <div class="form-group col-lg-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Nombre'][session.user.language]}} *
                    </label>
                    <div class="col-md-12">
                      <input v-model="newEmplacementData.label" id="inputNewEmplacementLabel" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

<!-- LOCALIZACION -->
                  <div class="form-group col-lg-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Localización'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input v-model="newEmplacementData.location" id="inputNewEmplacementLocation" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

                </div>

                <div class="row">
                  
<!-- COORDENADAS -->
                  <div class="form-group col-lg-6 col-12 p-1">
                    <label class="col-12  tx-bold tx-clomos-midtone">
                      {{dictionary['Geoposición'][session.user.language]}}
                    </label>
                    <!-- CONTENT Map -->
                    <GoogleMap
                    api-key="AIzaSyD1IQjTF3ZPy1I-xfKIytnl0eQ28VuFw-o" 
                    :center="newEmplacementLatLngPosition" :zoom="5"
                    @click="onSetNewCoordinates" 
                    id="emplacementMapContent" class="m-0" style="width: 100%; height: 250px; padding: 0  1.25rem">
                      <Marker :options="{position: newEmplacementLatLngPosition}" />
                    </GoogleMap>
                  </div>

                  <div class="d-flex flex-column col-lg-6 col-12 p-0">
                  
<!-- LATITUD -->
                    <div class="form-group col-12 p-1">
                      <label class="col-12  tx-bold tx-clomos-midtone">
                        {{dictionary['Latitud'][session.user.language]}}
                      </label>
                      <div class="col-md-12">
                        <input v-model="newEmplacementData.geoJSON.geometry.coordinates[0]" id="inputEditEmplacementLat" type="text" 
                        class="form-control form-control-line" style="color:black;">
                      </div>
                    </div>

<!-- LONGITUD -->
                    <div class="form-group col-12 p-1">
                      <label class="col-12  tx-bold tx-clomos-midtone">
                        {{dictionary['Longitud'][session.user.language]}} *
                      </label>
                      <div class="col-md-12">
                        <input v-model="newEmplacementData.geoJSON.geometry.coordinates[1]" id="inputEditEmplacementLng" type="text" 
                        class="form-control form-control-line" style="color:black;">
                      </div>
                    </div>

                  </div>

                </div>

                <div class="row">
                
<!-- SUBMIT -->
                  <div class="col-lg-6 col-12 p-1">
                  </div>

                  <div class="form-group col-lg-6 col-12 p-1">
                    <div class="col-12 mt-4">
                      <button type="button" id="btnSubmitCreateNewEmplacement" class="btn btn-clomos w-100"
                      @click="onCreateNewEmplacement">
                        {{dictionary['Crear nuevo emplazamiento'][session.user.language]}}
                      </button>
                    </div>
                  </div>

                </div>

              </div>
            </form>
              
          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import { GoogleMap, Marker } from "vue3-google-map";
import api from '@/services/api';

export default {
  name: "NewEmplacementModal",
  components: { 
    GoogleMap, Marker
  },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
  },
  data() {
    return {
      newEmplacementData: {
        nifCif: this.currentCompany.nifCif,
        label: "",
        location: "",
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [0,0]
        }
        }
      },
    }
  },
  computed: {
    newEmplacementLatLngPosition() {
      let position = {
        lat: Number(this.newEmplacementData.geoJSON.geometry.coordinates[0]), 
        lng: Number(this.newEmplacementData.geoJSON.geometry.coordinates[1])
      }
      return position;
    }
  },
  methods: {
    async onCreateNewEmplacement() {
            let valid = true;
      if (this.newEmplacementData.label === "") {
        $("#inputNewEmplacementLabel").get()[0].classList.add("missing")
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $("#inputNewEmplacementLabel").get()[0].classList.remove("missing")
        }, 3000)
        return;
      }
      let response = await api.postNewEmplacement(this.newEmplacementData);
      console.log(response);
      if (response["status-code"] === "201") {
        this.$emit('toggleNewEmplacementModal')
        window.alert("El dispositivo ha sido creado satisfactoriamente.");
      }
      else {
        window.alert("Error al crear el dispositivo.");
      }
    },
    onSetNewCoordinates(event) {
      this.newEmplacementData.geoJSON = {
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [Number(event.latLng.lat()), Number(event.latLng.lng())]
        }
      }
    }
  }
}
</script>

<style>

#newEmplacementModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>