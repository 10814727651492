<template>
  <div class="bg-clomos-portada home-container">
      
    <div v-if="this.session.user.nifCif != ''" class="home-section">
<!-- MENU Side -->
      <SideMenuComponent
      :session="session"
      :dictionary="dictionary"
      :visibility="visibility"
      :availableModules="session.authorisation.availableModules"
      v-on:toggleSideMenu="toggleSideMenu"
      v-on:logOutRequest="logOutRequest">
      </SideMenuComponent>

<!-- MENU Top -->
      <TopMenuComponent
      :session="session"
      :dictionary="dictionary"
      :visibility="visibility"
      :availableCompanies="availableCompanies"
      :currentCompany="currentCompany"
      v-on:setCurrentCompany="setCurrentCompany"
      v-on:toggleSideMenu="toggleSideMenu">
      </TopMenuComponent>

<!-- SECTION Profile -->
      <ProfileComponent v-if="this.$route.params.currentDisplay==='profile'"
      :session="session"
      :dictionary="dictionary"
      :countries="countries"
      :timeZones="timeZones"
      :currentCompany="currentCompany"
      :visibility="visibility"
      v-on:getCurrentUserData="getCurrentUserData"
      v-on:getCurrentCompanyData="getCurrentCompanyData">
      </ProfileComponent>

<!-- SECTION Dashboard -->
      <DashboardComponent v-if="this.$route.params.currentDisplay==='dashboard'"
      :session="session"
      :dictionary="dictionary"
      :visibility="visibility"
      :currentCompany="currentCompany"
      :dataTableOptions="dataTableOptions">
      </DashboardComponent>


<!-- SECTION Companies -->
      <CompaniesComponent v-if="this.$route.params.currentDisplay==='companies'"
      :session="session"
      :dictionary="dictionary"
      :countries="countries"
      :timeZones="timeZones"
      :visibility="visibility"
      :currentCompany="currentCompany"
      :availableCompanies="availableCompanies"
      :dataTableOptions="dataTableOptions"
      v-on:setCurrentCompany="setCurrentCompany"
      v-on:getAvailableCompanies="getAvailableCompanies">
      </CompaniesComponent>

<!-- SECTION Emplacements -->
      <EmplacementsComponent v-if="this.$route.params.currentDisplay==='emplacements'"
      :session="session"
      :dictionary="dictionary"
      :visibility="visibility"
      :currentCompany="currentCompany"
      :availableCompanies="availableCompanies"
      :dataTableOptions="dataTableOptions"
      v-on:setCurrentCompany="setCurrentCompany"
      v-on:getAvailableCompanies="getAvailableCompanies">
      </EmplacementsComponent>

<!-- SECTION Machines -->
      <MachinesComponent v-if="this.$route.params.currentDisplay==='machines'"
      :session="session"
      :dictionary="dictionary"
      :countries="countries"
      :visibility="visibility"
      :currentCompany="currentCompany"
      :availableCompanies="availableCompanies"
      :targetEmplacementId="''"
      :dataTableOptions="dataTableOptions"
      v-on:setCurrentCompany="setCurrentCompany"
      v-on:getAvailableCompanies="getAvailableCompanies">
      </MachinesComponent>

<!-- SECTION Devices -->
      <DevicesComponent v-if="this.$route.params.currentDisplay==='devices'"
      :session="session"
      :dictionary="dictionary"
      :countries="countries"
      :visibility="visibility"
      :currentCompany="currentCompany"
      :availableCompanies="availableCompanies"
      :targetEmplacementId="''"
      :dataTableOptions="dataTableOptions"
      v-on:setCurrentCompany="setCurrentCompany"
      v-on:getAvailableCompanies="getAvailableCompanies">
      </DevicesComponent>

<!-- SECTION Status -->
      <StatusComponent v-if="this.$route.params.currentDisplay==='status'"
      :session="session"
      :dictionary="dictionary"
      :countries="countries"
      :visibility="visibility"
      :currentCompany="currentCompany"
      :availableCompanies="availableCompanies"
      :requestedDeviceId="''"
      v-on:setCurrentCompany="setCurrentCompany">
      </StatusComponent>


<!-- SECTION Products -->
      <ProductsComponent v-if="this.$route.params.currentDisplay==='products'"
      :session="session"
      :dictionary="dictionary"
      :countries="countries"
      :visibility="visibility"
      :currentCompany="currentCompany"
      :availableCompanies="availableCompanies"
      :targetEmplacementId="''"
      :dataTableOptions="dataTableOptions"
      v-on:setCurrentCompany="setCurrentCompany"
      v-on:getAvailableCompanies="getAvailableCompanies">
      </ProductsComponent>

<!-- SECTION Stock -->
      <StockComponent v-if="this.$route.params.currentDisplay==='stocks'"
      :session="session"
      :dictionary="dictionary"
      :countries="countries"
      :visibility="visibility"
      :currentCompany="currentCompany"
      :availableCompanies="availableCompanies"
      :dataTableOptions="dataTableOptions"
      v-on:setCurrentCompany="setCurrentCompany"
      v-on:getAvailableCompanies="getAvailableCompanies">
      </StockComponent>

<!-- SECTION Receptions -->
      <ReceptionsComponent v-if="this.$route.params.currentDisplay==='receptions'"
      :session="session"
      :dictionary="dictionary"
      :countries="countries"
      :visibility="visibility"
      :currentCompany="currentCompany"
      :availableCompanies="availableCompanies"
      :dataTableOptions="dataTableOptions"
      v-on:setCurrentCompany="setCurrentCompany"
      v-on:getAvailableCompanies="getAvailableCompanies">
      </ReceptionsComponent>

<!-- SECTION Expeditions -->
      <ExpeditionsComponent v-if="this.$route.params.currentDisplay==='expeditions'"
      :session="session"
      :dictionary="dictionary"
      :countries="countries"
      :visibility="visibility"
      :currentCompany="currentCompany"
      :availableCompanies="availableCompanies"
      :dataTableOptions="dataTableOptions"
      v-on:setCurrentCompany="setCurrentCompany"
      v-on:getAvailableCompanies="getAvailableCompanies">
      </ExpeditionsComponent>

<!-- SECTION Orders -->
      <OrdersComponent v-if="this.$route.params.currentDisplay==='orders'"
      :session="session"
      :dictionary="dictionary"
      :countries="countries"
      :visibility="visibility"
      :currentCompany="currentCompany"
      :availableCompanies="availableCompanies"
      :dataTableOptions="dataTableOptions">
      </OrdersComponent>

<!-- SECTION Refunds -->
      <RefundsComponent v-if="this.$route.params.currentDisplay==='refunds'"
      :session="session"
      :dictionary="dictionary"
      :countries="countries"
      :visibility="visibility"
      :currentCompany="currentCompany"
      :availableCompanies="availableCompanies"
      :dataTableOptions="dataTableOptions">
      </RefundsComponent>

<!-- SECTION Charts -->
      <ChartsComponent v-if="this.$route.params.currentDisplay==='charts'"
      :session="session"
      :dictionary="dictionary"
      :countries="countries"
      :visibility="visibility"
      :currentCompany="currentCompany"
      :availableCompanies="availableCompanies"
      :timeZones="timeZones"
      v-on:setCurrentCompany="setCurrentCompany">
      </ChartsComponent>

<!-- SECTION Warnings -->
      <WarningsComponent v-if="this.$route.params.currentDisplay==='warnings'"
      :session="session"
      :dictionary="dictionary"
      :countries="countries"
      :visibility="visibility"
      :currentCompany="currentCompany"
      :availableCompanies="availableCompanies"
      :dataTableOptions="dataTableOptions">
      </WarningsComponent>

<!-- SECTION Users -->
      <UsersComponent v-if="this.$route.params.currentDisplay==='users'"
      :session="session"
      :dictionary="dictionary"
      :countries="countries"
      :timeZones="timeZones"
      :visibility="visibility"
      :currentCompany="currentCompany"
      :availableCompanies="availableCompanies"
      :dataTableOptions="dataTableOptions"
      v-on:setCurrentCompany="setCurrentCompany"
      v-on:getAvailableCompanies="getAvailableCompanies">
      </UsersComponent>
      
      <div class="p-0 pb-3"></div>
      <!--
      <div class="p-3">
        <small>Copyright &copy; 2022. D&A Innovative Systems.</small>
      </div>
      -->

    </div>

  </div>
</template>

<script>
import SideMenuComponent from '@/components/navigation/SideMenuComponent';
import TopMenuComponent from '@/components/navigation/TopMenuComponent';
import ProfileComponent from '@/components/profile/ProfileComponent';
import DashboardComponent from '@/components/dashboard/DashboardComponent';
import EmplacementsComponent from '@/components/emplacements/EmplacementsComponent';
import MachinesComponent from '@/components/machines/MachinesComponent';
import DevicesComponent from '@/components/devices/DevicesComponent';
import StatusComponent from '@/components/status/StatusComponent';
import CompaniesComponent from '@/components/companies/CompaniesComponent';
import ProductsComponent from '@/components/products/ProductsComponent';
import StockComponent from '@/components/stock/StockComponent';
import ReceptionsComponent from '@/components/receptions/ReceptionsComponent';
import ExpeditionsComponent from '@/components/expeditions/ExpeditionsComponent';
import OrdersComponent from '@/components/orders/OrdersComponent';
import RefundsComponent from '@/components/refunds/RefundsComponent';
import ChartsComponent from '@/components/charts/ChartsComponent';
import WarningsComponent from '@/components/warnings/WarningsComponent';
import UsersComponent from '@/components/users/UsersComponent';
// Clomos
import api from '@/services/api';

export default {
  name: "ClomosDevices",
  components: { 
    TopMenuComponent, 
    SideMenuComponent,  
    ProfileComponent, 
    DashboardComponent, 
    EmplacementsComponent, 
    MachinesComponent,
    DevicesComponent,
    StatusComponent,
    CompaniesComponent,
    ProductsComponent,
    StockComponent,
    ReceptionsComponent,
    ExpeditionsComponent,
    OrdersComponent,
    RefundsComponent,
    ChartsComponent,
    WarningsComponent,
    UsersComponent
  },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: {
    session: async function() {
      await this.getCurrentCompanyData();
      await this.getAvailableCompanies();
    }
  },
  data() {
    return {
      visibility: {
        sideMenu: true,
      },
      currentCompanyNifCif: this.$route.params.currentCompanyNifCif,
      currentCompany: {},
      availableCompanies: [],
    }
  },
  emits: [ "getCurrentUserData", "getCurrentCompanyData", "logOutRequest" ],
  methods: {
// DISPLAY
    toggleSideMenu() {
      this.visibility.sideMenu = !this.visibility.sideMenu;
    },
// SESION
    getCurrentUserData(callback) {
      this.$emit('getCurrentUserData', callback);
    },
    async getCurrentCompanyData(callback) {
      let response = await api.getCompanyData(this.currentCompanyNifCif);
      this.currentCompany = api.parseCompanyFromResponse(response);
      if (callback && typeof callback === "function") callback(this.currentCompany);
    },
    async getAvailableCompanies(callback, forced) {
      if (this.availableCompanies.length > 0 && forced === undefined) {
        if (callback && typeof callback === "function") {
          callback(this.availableCompanies);
        }
        return;
      }
      let availableCompanies = [];
      let request = {
        nifCif: this.session.user.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let responseUserCompany = await api.getCompanyData(this.session.user.nifCif);
      let uc = api.parseCompanyFromResponse(responseUserCompany);
      availableCompanies.push(uc);
      let responseAvailableCompanies = await api.getCompanyList(request);
      let ac = api.parseAvailableCompaniesFromResponse(responseAvailableCompanies);
      for (let c in ac) availableCompanies.push(ac[c]);
      this.availableCompanies = availableCompanies;
      if (callback && typeof callback === "function") callback(this.availableCompanies);
    },
// SET
    async setCurrentCompany(nifCif, callback) {
      if(nifCif==="showAll") {
        this.currentCompanyNifCif = "showAll";
        this.currentCompany = {
          nifCif: "showAll",
          name:"Show All"
        }
      }
      else {
        this.currentCompanyNifCif = nifCif;
        let response = await api.getCompanyData(nifCif);
        this.currentCompany = api.parseCompanyFromResponse(response);
      }
      this.$router.replace({params: {currentCompanyNifCif: this.currentCompanyNifCif, targetEntity: ""}})
      if (callback && typeof callback === "function") callback(this.currentCompany);
    },
    logOutRequest() {
      this.$emit('logOutRequest');
    }
  },
  async created() {
    await this.getCurrentCompanyData();
    await this.getAvailableCompanies();
  }
}
</script>

<style>
.home-container {
  height: auto;
  min-height: 100vh;
  min-width: 450px;
}

.home-section {
  padding-top: 4.5rem;
}
.section-container-sm {
  padding-top: 1rem;
  padding-left: 18.25rem;
}

.section-container-lg {
  padding-top: 1rem;
  padding-left: 5.5rem;
}

.header-tab-button {
  width: 100%; 
  min-width: auto; 
  height: 100%; 
  color: white; 
  border: solid 1px white;
  vertical-align: middle
}

@media (max-width: 767px) {
  .section-container-sm {
    display: none;
  }

  .section-container-lg {
    padding-top: 1rem;
    padding-left: 5.5rem;
  }
}
</style>