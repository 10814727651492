<template>
  <router-view  
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :dataTableOptions="dataTableOptions"
  v-on:logInRequest="logInRequest"
  v-on:toggleLanguage="toggleLanguage"
  v-on:getCurrentUserData="getCurrentUserData"
  v-on:getCurrentCompanyData="getCurrentCompanyData"
  v-on:logOutRequest="logOutRequest">
  </router-view>
</template>

<script>
import languagesJson from "./assets/target/json/languages_es_en.json";
import countriesJson from "./assets/target/json/countries_es_en.json";
import timeZonesJson from "./assets/target/json/timezones_en.json";
import dataTableOptionsJson from "./assets/target/json/datatables_options.json";
// Clomos
import api from "@/services/api";

export default {
  name: "App",
  data() {
    return {
      dictionary: JSON.parse(JSON.stringify(languagesJson)),
      countries: JSON.parse(JSON.stringify(countriesJson)),
      timeZones: JSON.parse(JSON.stringify(timeZonesJson)),
      session: {
        user: {
          id: localStorage.getItem("ClomosUserId")||"",
          name: "",
          lastName: "",
          nifCif: "",
          email: "",
          state: "",
          province: "",
          country: "",
          adress1: "",
          adress2: "",
          telephone: "",
          rol: "",
          language: localStorage.getItem("language")||"es",
          unitTemperature: localStorage.getItem("unitTemperature")||"",
          unitDistance: localStorage.getItem("unitDistance")||"",
          dateTimeZone: localStorage.getItem("dateTimeZone")||Intl.DateTimeFormat().resolvedOptions().timeZone,
          dateTimeFormat: localStorage.getItem("dateTimeFormat")||"DD/MM/YYYY",
          dateTime: Date.now(),
          fieldSeparator: "",
          habitual: "",
          creationDateTime: "",
          lastConnexion: ""
        },
        authorisation: {
          availableModules: []
        },
      },
      browser: "",
      dataTableOptions: JSON.parse(JSON.stringify(dataTableOptionsJson)),
    }
  },
  methods: {
// SESION
    async logInRequest(formLogIn, callback) {
      if (formLogIn.rememberMe === true)
      {
          localStorage.setItem("ClomosUserId", formLogIn.userId);
          localStorage.setItem("ClomosRememberMe",  true);
      }
      else
      {
        localStorage.removeItem("ClomosUserId");
        localStorage.setItem("ClomosRememberMe",  false);
      }
      let response = await api.logInRequest(formLogIn);
      let session = api.parseSessionDataFromResponse(response);
      if (session.user != undefined) {
        this.session = session;
        sessionStorage.setItem("ClomosSession", JSON.stringify(session))
        this.session.browser = this.browser;
      }
      else {
        sessionStorage.clear();
      }
      if (callback && typeof callback === "function") callback(session);
    },

    async logOutRequest() {
      let response = await api.logOutRequest(this.session.user.id);
      if (response) {
        this.session = {
          user: {
            id: localStorage.getItem("ClomosUserId")||"",
            name: "",
            lastName: "",
            nifCif: "",
            email: "",
            province: "",
            state: "",
            country: "",
            adress1: "",
            adress2: "",
            telephone: "",
            rol: "",
            language: localStorage.getItem("language")||"es",
            unitTemperature: localStorage.getItem("unitTemperature")||"",
            unitDistance: localStorage.getItem("unitDistance")||"",
            dateTimeZone: localStorage.getItem("dateTimeZone")||Intl.DateTimeFormat().resolvedOptions().timeZone,
            dateTimeFormat: localStorage.getItem("dateTimeFormat")||"DD/MM/YYYY",
            dateTime: Date.now(),
            habitual: "",
            fieldSeparator: "",
            creationDateTime: "",
            lastConnexion: ""
          },
          authorisation: {
              availableModules: []
          }
        }
        sessionStorage.clear();
        let path = "/"+localStorage.getItem("language")+"/logIn";
        this.$router.replace({path: path });
      }
    },
// GET CurrentSessionData
    async getCurrentSessionData(callback) {
      let session; 
      if (sessionStorage.getItem("ClomosSession") === null) {
        let response = await api.persistenceLogInRequest(localStorage.getItem("ClomosUserId"));
        session = api.parseSessionDataFromResponse(response);
      }
      else {
        session = JSON.parse(sessionStorage.getItem("ClomosSession"));
      }
      if (session.user != undefined) {
        this.session = session;
        sessionStorage.setItem("ClomosSession", JSON.stringify(session))
        this.session.browser = this.browser;
        this.toggleLanguage(session.user.language);
      }
      else {
        sessionStorage.clear()
      }
      if (callback && typeof callback === "function") callback(this.session);
    },
// GET CurrentUserData
    async getCurrentUserData(callback) {
      let response = await api.getUserData(this.session.user.id);
      let user = api.parseUserFromResponse(response);
      if (user != {})  {
        this.session.user.id = user.id,
        this.session.user.name = user.name,
        this.session.user.lastName = user.lastName,
        this.session.user.nifCif = user.nifCif,
        this.session.user.email = user.email,
        this.session.user.telephone = user.telephone,
        this.session.user.province = user.province,
        this.session.user.state = user.state,
        this.session.user.country = user.country,
        this.session.user.adress1 = user.adress1,
        this.session.user.adress2 = user.adress2,
        this.session.user.rol = user.rol,
        this.session.user.language = user.language,
        this.session.user.unitTemperature = user.unitTemperature,
        this.session.user.unitDistance = user.unitDistance,
        this.session.user.dateTimeZone = user.dateTimeZone,
        this.session.user.dateTimeFormat = user.dateTimeFormat,
        this.session.user.dateTime = user.dateTime,
        this.session.user.habitual = user.habitual,
        this.session.user.fieldSeparator = user.fieldSeparator,
        this.session.user.creationDateTime = user.creationDateTime
        this.toggleLanguage(user.language);
      }
      if (callback && typeof callback === "function") callback(this.session.user);
    },
// LANGUAGE
    toggleLanguage(language) {
      this.session.user.language = language;
      localStorage.setItem("language",language);
      if (this.$route.params.language != undefined)this.$router.replace({params: {language: language}})
    },
  },
  async created() {
// Detect browser
    let userAgent = navigator.userAgent;
    
    if(userAgent.match(/chrome|chromium|crios/i)){
      this.browser = "chrome";
    }else if(userAgent.match(/firefox|fxios/i)){
      this.browser = "firefox";
    }  else if(userAgent.match(/safari/i)){
      this.browser = "safari";
    }else if(userAgent.match(/opr\//i)){
      this.browser = "opera";
    } else if(userAgent.match(/edg/i)){
      this.browser = "edge";
    }else{
      this.browser="No browser detection";
    }

// Update Local Storage
    if (localStorage.getItem("language") != "es"
    && localStorage.getItem("language") != "en")
      localStorage.setItem("language","es");

    if (localStorage.getItem("dateTime") === null
    || localStorage.getItem("dateTime") === "undefined"
    || localStorage.getItem("dateTime") != Date.now())
      localStorage.setItem("dateTime", Date.now());

    if (localStorage.getItem("dateTimeZone") === null
    || localStorage.getItem("dateTimeZone") === "undefined"
    || localStorage.getItem("dateTimeZone") != Intl.DateTimeFormat().resolvedOptions().timeZone)
      localStorage.setItem("dateTimeZone", Intl.DateTimeFormat().resolvedOptions().timeZone);

// Update URL
    await this.$router.isReady()
    if (localStorage.getItem("ClomosAuthToken") === null)
      this.$router.replace({path: "/"+ localStorage.getItem("language")+"/logIn/"});
    else {
      await this.getCurrentSessionData();
      if (this.session.user.nifCif === "") {
        this.$router.replace({path: "/"+ localStorage.getItem("language")+"/logIn/"});
        localStorage.removeItem("ClomosAuthToken");
        localStorage.removeItem("ClomosUserId");
      }
      else if (this.$route.params.currentDisplay===undefined) {
        this.$router.replace({path: "/"+ localStorage.getItem("language")+"/"+this.session.user.nifCif+"/companies/"});
      }
    }
  }
}
</script>

<style>
@import "./assets/target/styles/clomos.css";
@import "./assets/target/styles/bootstrap.css";
@import "datatables.net-dt";

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-family: "Segoe UI";
  font-weight: 400;
  color: #333;
}
</style>
