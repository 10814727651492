<template>
<!-- MODAL New Reception -->
  <div id="newReceptionModal"
  class="col-12 justify-content-center pe-6"
  style="padding: 15vh 20%">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">

<!-- HEAD New Reception -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100">
        <div class="col-10">
          <h4 class="tx-left tx-clomos-white mb-2 ps-1 pt-2">
            <ion-icon name="arrow-undo-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary['Nueva recepción'][session.user.language]}}
          </h4>
        </div>

        <div class="col-2 m-0 p-0" style="display: flex; justify-content: end;"
        @click="$emit('toggleNewReceptionModal')">	
          <ion-icon name="close" class="ms-auto tx-46 tx-white align-bottom"></ion-icon>
        </div>
      </div>

      <div class="col-12 p-0 w-100">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="card-body">

<!-- CONTENT New Reception -->
            <form class="form-material border-1 p-3" style="overflow: scroll; overflow-x: auto; overflow-y: hidden;">
              <div class="container-fluid">
                
<!-- ALBARAN SRC-->
                <label class="col-12 tx-bold tx-clomos-dark text-nowrap ps-3 pe-3">
                  {{dictionary['Albarán'][session.user.language]}}
                </label>

                <div class="row pt-3 ps-2 pe-2">
                  <div id="recipeUploadFormContainer col-12" class="w-100 p-3 pt-0" style="display: flex; justify-content: space-around; gap: 1rem;">
                    <input type="file" id="recipeUploadSrc" class=" w-100 file-button btn btn-secondary rounded" style="height: 3rem; min-width: 180px;"
                    @change="onRecipeChanged" accept=".pdf">
                  </div>
                </div>
                
                <div class="col-12 p-3">
                  <hr class="tx-clomos-dark m-0">
                </div>

                <div class="col-12 ps-2 pe-2">
                  <label class="col-12 tx-bold tx-clomos-dark text-nowrap ps-3 pe-3">
                    {{dictionary['Orden'][session.user.language]}}
                  </label>
                </div>

                <div class="row ms-2 me-2">
<!-- PRODUCTO -->
                  <div class="form-group col-lg-3 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Referencia'][session.user.language] }}
                    </label>
                    <div class="col-12 p-0">
                      <input v-model="targetProduct.reference" id="inputSearchProductId" type="text" autocomplete="off"
                      list="productStocks" class="form-control form-control-line ps-0 pe-0" style="color:black;"
                      @input="(event)=>{onTargetProduct(event)}">
                    </div>
                    <datalist id="productStocks">
                      <option v-for="product in filteredProductList" :key="product"
                      :value="(product.variables.dynamic.referencia!=undefined)?product.variables.dynamic.referencia.value:product.id"> 
                        {{ product.label }}
                      </option>
                    </datalist>
                  </div>

                  <div class="form-group col-lg-1 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Talla'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <input v-model="inputNewReceptionSize" id="inputNewReceptionSize" type="text" :disabled="isSizeDisabled"
                      class="form-control form-control-line ps-0 pe-0" style="color:black;">
                    </div>
                  </div>
                  
                  <div class="form-group col-lg-2 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Color'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <input v-model="inputNewReceptionColor" id="inputNewReceptionColor" type="text" :disabled="isColorDisabled"
                      class="form-control form-control-line ps-0 pe-0" style="color:black;">
                    </div>
                  </div>

                  <div class="form-group col-lg-3 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Caducidad'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <input v-model="inputNewReceptionExpirationDate" id="inputNewReceptionExpirationDate" type="date" :disabled="isExpirationDateDisabled"
                      class="form-control form-control-line ps-0 pe-0" style="color:black;">
                    </div>
                  </div>

                  <div class="form-group col-lg-2 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Unidades'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <input v-model="inputNewReceptionUnits" id="inputNewReceptionUnits" type="number" 
                      class="form-control form-control-line ps-0 pe-0" style="color:black;">
                    </div>
                  </div>

                  <div class="form-group col-lg-1 col-12 p-0 pt-3 m-0">
                    <ion-icon name="add-circle-outline" class="tx-clomos-dark targets-icons m-0 p-0 pt-2 tx-40" style="vertical-align: middle;"
                    @click="(targetProduct.reference!='')?this.newReceptionData.targetList[targetProduct.id] = {
                      'id':targetProduct.id, 
                      'reference':targetProduct.reference, 
                      'label':targetProduct.label, 
                      'color':inputNewReceptionColor, 
                      'size':inputNewReceptionSize, 
                      'expirationDate':inputNewReceptionExpirationDate, 
                      'units': inputNewReceptionUnits
                    }:''">
                    </ion-icon>
                  </div>

                </div>

                <div class="row ms-2 me-2" v-for="target in newReceptionData.targetList" :key="target">
<!-- TARGETS -->
                  <div class="form-group col-lg-3 col-12 p-1">
                    <div class="col-md-12 p-0">
                      <div class="border-bottom tx-13 pb-1" style="color:black;">
                      {{ target.reference }}
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-lg-1 col-12 p-1">
                    <div class="col-md-12 p-0">
                      <div class="border-bottom tx-13 pb-1" style="color:black;">
                      {{ (target.size != "") ?target.size :"-" }}
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-lg-2 col-12 p-1">
                    <div class="col-md-12 p-0">
                      <div class="border-bottom tx-13 pb-1" style="color:black;">
                      {{ (target.color != "") ?target.color :"-" }}
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-lg-3 col-12 p-1">
                    <div class="col-md-12 p-0">
                      <div class="border-bottom tx-13 pb-1" style="color:black;">
                      {{ (target.expirationDate != "") ?target.expirationDate :"-" }}
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-lg-2 col-12 p-1">
                    <div class="col-md-12 p-0">
                      <div class="border-bottom tx-13 pb-1" style="color:black;">
                      {{ target.units }}
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-lg-1 col-12 p-0 m-0">
                    <ion-icon name="remove-circle-outline" class="tx-clomos-dark targets-icons m-0 p-0 pt-2 tx-40" style="vertical-align: middle;"
                    @click="delete this.newReceptionData.targetList[target.id]"></ion-icon>
                  </div>

                </div>

                <div class="row">
<!-- SUBMIT -->

                  <div class="form-group col-lg-6 col-12 p-0 pt-3 m-0 ms-auto me-auto">
                    <div class="col-md-12">
                      <button type="button" id="btnSubmitCreateNewReception" class="btn btn-clomos w-100"
                      @click="setNewReceptionData">
                        {{ dictionary['Crear nueva recepción'][session.user.language] }}
                      </button>
                    </div>
                  </div>

                </div>

              </div>

            </form>
              
          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "NewReceptionModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    productId: {
      type: String,
      requiered: true,
    },
  },
  data() {
    return {
      newReceptionData: {
        userId: this.session.user.id,
        receiptFile: "",
        receiptSrc: "",
        targetList: {}
      },
      targetProduct: {
        id: "",
        reference: "",
        label: "",
      },
      availableProductList: [],
      inputNewReceptionSize: "",
      inputNewReceptionColor: "",
      inputNewReceptionExpirationDate: "",
      inputNewReceptionUnits: 0,
      isSizeDisabled : true,
      isColorDisabled : true,
      isExpirationDateDisabled : true,
    }
  },
  computed: {
    filteredProductList() {
      let filteredProductList = [];
      for (let i in this.availableProductList)
        if (this.availableProductList[i].variables.dynamic.referencia.value.includes(this.targetProduct.reference)
        || this.targetProduct.reference === "")
          filteredProductList.push(this.availableProductList[i]);
      return filteredProductList;
    }
  },
  emits: ["toggleNewReceptionModal"],
  methods: {
    // GET
    async getProductList() {
      let request = {
        nifCif: "showAll",
        limit: 0,
        offset: 0,  
        where: ""
      }
      let response = await api.getProductList(request);
      this.availableProductList = api.parseProductsFromResponse(response);
    },
    // SET
    async setNewReceptionData() {
      let valid = true;
      if (Object.keys(this.newReceptionData.targetList) == 0) {
        $("#inputSearchProductId").get()[0].classList.add("missing")
        $("#inputNewReceptionSize").get()[0].classList.add("missing")
        $("#inputNewReceptionColor").get()[0].classList.add("missing")
        $("#inputNewReceptionExpirationDate").get()[0].classList.add("missing")
        $("#inputNewReceptionUnits").get()[0].classList.add("missing")
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $("#inputSearchProductId").get()[0].classList.remove("missing")
          $("#inputNewReceptionSize").get()[0].classList.remove("missing")
          $("#inputNewReceptionColor").get()[0].classList.remove("missing")
          $("#inputNewReceptionExpirationDate").get()[0].classList.remove("missing")
          $("#inputNewReceptionUnits").get()[0].classList.remove("missing")
          $("#inputNewReceptionPlanningDateTime").get()[0].classList.remove("missing")
        }, 3000)
        return;
      }
      let response = await api.postNewReception(this.newReceptionData);
      console.log(response);
      if (response["status-code"] === "200") {
        this.$emit('toggleNewReceptionModal');
        window.alert("La recepción ha sido creada satisfactoriamente.");
      }
      else {
        window.alert("Error al crear la recepción.");
      }
    },
    onRecipeChanged(event) {
      if (event.target.files[0] != undefined && event.target.files[0].size > 2097152) {
        alert("El tamaño del fichero debe ser inferior a 2Mb.");
        return;
      }
      this.newReceptionData.receiptFile = event.target.files[0];
      this.newReceptionData.receiptSrc = event.target.files[0].name;
    },
  // TARGET
    onTargetProduct(event) {
      this.inputNewReceptionSize = "";
      this.inputNewReceptionColor = "";
      this.inputNewReceptionExpirationDate = "";
      let targetReference = event.target.value;
      for (let i in this.availableProductList) {
        if (this.availableProductList[i].variables.dynamic.referencia != undefined
        && this.availableProductList[i].variables.dynamic.referencia.value === targetReference) {
          this.targetProduct = {
            id: this.availableProductList[i].id,
            reference: this.targetProduct.reference,
            label: this.availableProductList[i].label,
          }
          this.isSizeDisabled = (this.availableProductList[i].variables.dynamic.talla === undefined);
          this.isColorDisabled = (this.availableProductList[i].variables.dynamic.color === undefined);
          this.isExpirationDateDisabled = (this.availableProductList[i].variables.dynamic.fechaCaducidad === undefined);
        }
      }
    }
  },
  async created() {
    await this.getProductList();
  }
}
</script>

<style>

#newReceptionModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.targets-icons:hover {
  color: var(--main-dark-50);
}

@media (max-width: 767px) {
  #stockImgUploadFormContainer {
    flex-direction: column;
  }
}
</style>