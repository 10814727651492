<template>
<!-- MODAL Edit Product -->
  <div id="editProductModal"
  class="col-12 justify-content-center pe-6"
  style="padding: 15vh 20%">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">
      
<!-- HEAD Edit Product -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100">
        <div class="col-10">
          <h4 class="tx-left tx-clomos-white mb-2 ps-1 pt-2">
            <ion-icon name="cube-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary['Editar producto'][session.user.language]}}
          </h4>
        </div>

        <div class="col-2 m-0 p-0" style="display: flex; justify-content: end;"
        @click="$emit('toggleEditProductModal')">	
          <ion-icon name="close" class="ms-auto tx-46 tx-white align-bottom"></ion-icon>
        </div>
      </div>

<!-- CONTENT Edit Product -->
      <div class="col-12 p-0 w-100">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="card-body">


<!-- FORM Edit Product-->
            <form class="form-material border-1 p-3 pt-0 overflow-hidden">
              <div class="container-fluid p-3">
                
                <div class="row">
                  
<!-- NOMBRE -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Nombre'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input v-model="editProductData.label" id="inputEditProductLabel" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

<!-- REFERENCIA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Referencia'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input v-model="editProductData.reference" id="inputEditProductReference" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

                </div>

              
                <div class="row">

<!-- DESCRIPCIÓN -->
                  <div class="form-group col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Descripción'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <textarea v-model="editProductData.description" id="inputNewProductDescription" type="text" rows="1"
                      class="form-control form-control-line" style="color:black;"/>
                    </div>
                  </div>
                
                </div>
              
                <div class="row">
                
<!-- UNIDAD DE MEDIDA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Unidad de medida'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <select v-model="editProductData.unit"
                      id="inputEditProductUnit"
                      class="form-control form-control-line" style="color:black;">
                        <option value="kilo">
                          {{dictionary["Kilo"][session.user.language]}}
                        </option>
                        <option value="metro">
                          {{dictionary["Metro"][session.user.language]}}
                        </option>
                        <option value="litro">
                          {{dictionary["Litro"][session.user.language]}}
                        </option>
                        <option value="unidades">
                          {{dictionary["Unidad"][session.user.language]}}
                        </option>
                      </select>
                    </div>
                  </div>

<!-- PESO POR UNIDAD -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Peso por unidad'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input v-model="editProductData.unitWeight" id="inputEditProductUnitWeight" type="number" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>
                
                </div>

                <div class="row">
                
<!-- APILABLE -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Apilable'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <div id="inputEditProductStackable" 
                      class="inputEditDevice form-control border-0 tx-bold w-100"
                      :class="editProductData.stackable"
                      @click="(editProductData.stackable==='true')?editProductData.stackable='false':editProductData.stackable='true'">
                        {{ dictionary[editProductData.stackable][session.user.language] }}
                      </div>
                    </div>
                  </div>

<!-- DESCATALOGADO -->
                  <div v-if="editProductData.discontinued!=undefined" class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Descatalogado'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <div id="inputEditProductDiscontinued" 
                      class="inputEditDevice form-control border-0 tx-bold w-100"
                      :class="editProductData.discontinued"
                      @click="(editProductData.discontinued==='true')?editProductData.discontinued='false':editProductData.discontinued='true'">
                        {{ dictionary[editProductData.discontinued][session.user.language] }}
                      </div>
                    </div>
                  </div>

<!-- Alerta de stock -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Alerta de stock'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input v-model="editProductData.stockWarning" id="inputEditProductStockAlert" type="number" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>
                
                </div>

                <div class="row">

<!-- TEMERATURA DE CONSERVACIÓN -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Temperatura de conservación'][session.user.language]}}
                    </label>
                    <div class="col-lg-12">
                      <select v-model="editProductData.conservationTemperature"
                      id="selectEditProductConservationTemperature"
                      class="form-control form-control-line" style="color:black;">
                        <option value="frio">
                          {{dictionary['Frío'][session.user.language]}}
                        </option>
                        <option value="ambiente">
                          {{dictionary['Ambiente'][session.user.language]}}
                        </option>
                        <option value="ultracongelado">
                          {{dictionary['Ultracongelado'][session.user.language]}}
                        </option>
                      </select>
                    </div>
                  </div>

<!-- PRIORIDAD DE SALIDA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Prioridad de salida'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <select v-model="editProductData.priority"
                      id="selectEditProductPriority"
                      class="form-control form-control-line" style="color:black;">
                        <option value="entrada">
                          {{dictionary['Fecha de entrada'][session.user.language]}} (FIFO)
                        </option>
                        <option value="salida">
                          {{dictionary['Fecha de salida'][session.user.language]}}
                        </option>
                        <option value="caducidad">
                          {{dictionary['Fecha de caducidad'][session.user.language]}}
                        </option>
                      </select>
                    </div>
                  </div>

                </div>

                <div class="row">

<!-- TIENDA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Empresa'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <select
                      v-model="editProductData.nifCif"
                      class="form-control form-control-line" style="color:black;">
                        <option v-for="company in availableCompanies" :key="company.nifCif"
                        :v-if="company.nifCif != session.user.nifCif
                        && company.relation != undefined
                        && company.relation.referenceCompany === this.session.user.nifCif"
                        :value="company.nifCif">
                          {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
                        </option>
                      </select>
                    </div>
                  </div>
                  
<!-- CATEGORÍA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Categoría'][session.user.language]}}
                    </label>

                    <div class="col-md-12">
                      <select v-model="editProductData.category"
                      @change="(editProductData.category==='alimentacion'||editProductData.category==='belleza')?editProductData.priority='caducidad':editProductData.priority='entrada'"
                      id="selectEditProductCategory"
                      class="form-control form-control-line" style="color:black;">
                        <option value="otro">
                          {{dictionary["Otro"][session.user.language]}}
                        </option>
                        <option value="alimentacion">
                          {{dictionary["Alimentación"][session.user.language]}}
                        </option>
                        <option value="bricolaje">
                          {{dictionary["Bricolaje"][session.user.language]}}
                        </option>
                        <option value="belleza">
                          {{dictionary["Belleza"][session.user.language]}}
                        </option>
                        <option value="electrodomesticos">
                          {{dictionary["Electrodomésticos"][session.user.language]}}
                        </option>
                        <option value="menaje">
                          {{dictionary["Menaje"][session.user.language]}}
                        </option>
                        <option value="moda">
                          {{dictionary["Moda"][session.user.language]}}
                        </option>
                        <option value="papeleria">
                          {{dictionary["Papelería"][session.user.language]}}
                        </option>
                      </select>
                    </div>
                  </div>

                </div>

                <div class="col-12 p-0 ps-2 ms-1 pe-3">
                  <hr class="tx-clomos-dark">
                </div>

                <div class="row ps-2 pe-2">
                  <label class="col-12 tx-bold tx-clomos-dark text-nowrap ps-3 pe-3">
                    {{dictionary['Dimensiones'][session.user.language]}} <span class="ps-1">(mm)</span>
                  </label>
                </div>

                <div class="row">
<!-- ALTO -->
                  <div class="form-group col-md-4 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Alto'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input v-model="editProductData.volume.height" id="inputEditProductHeight" type="number" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

<!-- ANCHO -->
                  <div class="form-group col-md-4 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Ancho'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input v-model="editProductData.volume.width" id="inputEditProductWidth" type="number" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

<!-- FONDO -->
                  <div class="form-group col-md-4 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Fondo'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input v-model="editProductData.volume.depth" id="inputEditProductDepth" type="number" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

                </div>

                <div class="row">

                  <div class="col-md-6 col-12 m-0 p-0"></div>

<!-- SUBMIT -->
                  <div class="form-group col-lg-6 col-12 p-1">
                    <div class="col-12 mt-4">
                      <button type="button" @click="setEditProductData"
                      id="btnSubmitCreateEditUser" class="btn btn-clomos w-100">
                        {{dictionary['Guardar cambios'][session.user.language]}}
                      </button>
                    </div>
                  </div>

                </div>
                
              </div>
            </form>

<!-- FORM Product Picture-->
            <div class="col-12 pt-3 p-0 overflow-hidden" v-if="editProductData.imgSrc!=undefined">
              <div class="justify-content-center bg-clomos-midtone rounded-5">
                
                <label class=" col-12 p-3 pb-0 tx-white tx-bold text-nowrap">
                  {{dictionary["Imagen del producto"][session.user.language].toUpperCase()}}
                </label>
                <label class=" col-12 p-3 pt-0 tx-white tx-light text-nowrap">
                  {{dictionary["Tamaño recomendado"][session.user.language]+': 1000x1000px'}}
                </label>
                
                <img id="productImage" class="w-100" style="max-width: 750px; width: auto; padding: 0 1rem 1rem 1rem;" :src="displayProductImage(editProductData.imgSrc)">

                <div v-if="editProductImageForm.file!=''" class="myCompanyImagePreview w-100 p-3 m-auto">
                  <div class="d-flex flex-column">
                    <button type="button" title="Remove file" @click="onRemoveEditProductImage()" style="line-height: 0;" class="p-1 m-0 rounded ms-auto">
                      <ion-icon name="close" class="tx-18 tx-black align-bottom"></ion-icon>
                    </button>
                    <img style="max-width: 750px; width: auto; padding-top: 1rem; margin: auto;" :src="displayEditProductThumbnail(editProductImageForm.file)" />
                  </div>
                </div>

                <div id="productImageUploadFormContainer" class="w-100 p-3 pt-0" style="display: flex; justify-content: space-around; gap: 1rem;">

                  <input type="file" id="productImageUploadSrc" class="w-100 file-button btn btn-secondary rounded" style="height: 3rem; min-width: 180px;"
                  @change="onChangeEditProductImage" accept=".png, .jpg">

                  <button type="button" id="productImageUploadSubmit" class=" w-100 file-button btn btn-dark rounded" style="height: 3rem; min-width: 180px;"
                  @click="setEditProductImage">
                    <ion-icon name="log-in-outline" class="me-3"></ion-icon>
                    {{dictionary["Guardar archivo"][session.user.language]}}
                  </button>

                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "EditProductModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    productId: {
      type: String,
      requiered: true,
    },
  },
  data() {
    return {
      editProductData: {
        nifCif: "",
        id: "",
        label: "",
        reference : "",
        description: "",
        unit: "",
        stockWarning: 0,
        conservationTemperature: "",
        priority:"",
        stackable: "false",
        discontinued: "false",
        category: "otro",
        imgSrc : undefined, 
        unitWeight: 0,
        volume: {
          height: 0,
          width: 0,
          depth: 0
        },
        variableIdList: {}
      },
      editProductImageForm: {
        productId: this.productId,
        variableId: "",
        nifCif: "",
        src: "",
        file: ""
      },
    }
  },
  emits: ["toggleEditProductModal"],
  methods: {
    // DISPLAY
    displayProductImage(src) {
      if (src!=undefined && src!='') {
        try {
          return this.$clientConfig.api + '/ficheros/ficheros.php?fichero='+src+'&identificadorusuario='+this.session.user.id+'&Token='+localStorage.getItem("ClomosAuthToken");
        }
        catch(e) { 
          console.warn(e);
        }
      }
      return require('/src/assets/target/img/placeholder_image.jpg');
    },
    displayEditProductThumbnail(file) {
      if (file === '' || file === undefined) return "";
      let fileSrc = URL.createObjectURL(file);
        setTimeout(() => {
            URL.revokeObjectURL(fileSrc);
        }, 1000);
      return fileSrc;
    },
    onChangeEditProductImage(event) {
      if (event.target.files[0] != undefined && event.target.files[0].size > 2097152) {
        alert("El tamaño del fichero debe ser inferior a 2Mb.");
        return;
      }
      this.editProductImageForm.nifCif = this.editProductData.nifCif;
      this.editProductImageForm.file = event.target.files[0];
      this.editProductImageForm.src = event.target.files[0].name;
    },
    onRemoveEditProductImage() {
      $("#productImageUploadSrc").get()[0].value="";
      this.editProductImageForm.nifCif = "";
      this.editProductImageForm.file = "";
      this.editProductImageForm.src = "";
    },
    // GET 
    async getEditProductData(callback) {
      let response = await api.getProductData(this.productId);
      api.parseProductFromResponse(response, (product) => {
        if(product != {}) {
          this.editProductData.nifCif = product.nifCif; 
          this.editProductData.id = product.id; 
          this.editProductData.label = product.label; 
          this.editProductData.reference = product.variables.dynamic.referencia.value;
          this.editProductData.description = product.variables.dynamic.descripcion.value; 
          this.editProductData.unit = product.variables.dynamic.unidad.value; 
          this.editProductData.unitWeight = product.variables.analog.pesoPorUnidad.value; 
          this.editProductData.stockWarning = Number(product.variables.analog.alertaStock.value).toFixed(0); 
          this.editProductData.conservationTemperature = product.variables.dynamic.temperaturaConservacion.value; 
          this.editProductData.priority = product.variables.dynamic.prioridadSalida.value;
          this.editProductData.stackable = product.variables.digital.apilable.value;
          this.editProductData.discontinued = (product.variables.digital.descatalogado!=undefined)?product.variables.digital.descatalogado.value:undefined;
          this.editProductData.category = (product.category!="") ?product.category :"otro"; 
          this.editProductData.volume = {height: JSON.parse(product.variables.dynamic.volumen.value).alto, width: JSON.parse(product.variables.dynamic.volumen.value).ancho, depth: JSON.parse(product.variables.dynamic.volumen.value).fondo}
          this.editProductData.imgSrc = (product.variables.dynamic.urlImagen!=undefined)?product.variables.dynamic.urlImagen.value:undefined; 
          this.editProductData.variableIdList = {
            reference : (product.variables.dynamic.referencia!=undefined) ?product.variables.dynamic.referencia.variableId :'',
            description : (product.variables.dynamic.descripcion!=undefined) ?product.variables.dynamic.descripcion.variableId :'',
            unit : (product.variables.dynamic.unidad!=undefined) ?product.variables.dynamic.unidad.variableId :'',
            unitWeight : (product.variables.analog.pesoPorUnidad!=undefined) ?product.variables.analog.pesoPorUnidad.variableId :'',
            stockWarning : (product.variables.analog.alertaStock!=undefined) ?product.variables.analog.alertaStock.variableId :'',
            conservationTemperature : (product.variables.dynamic.temperaturaConservacion!=undefined) ?product.variables.dynamic.temperaturaConservacion.variableId :'',
            priority : (product.variables.dynamic.prioridadSalida!=undefined) ?product.variables.dynamic.prioridadSalida.variableId :'',
            stackable : (product.variables.digital.apilable!=undefined) ?product.variables.digital.apilable.variableId :'',
            discontinued : (product.variables.digital.descatalogado!=undefined) ?product.variables.digital.descatalogado.variableId :'',
            volume : (product.variables.dynamic.volumen!=undefined) ?product.variables.dynamic.volumen.variableId :'',
            imgSrc : (product.variables.dynamic.urlImagen!=undefined) ?product.variables.dynamic.urlImagen.variableId :'',
          }
        this.editProductImageForm.variableId = this.editProductData.variableIdList.imgSrc;
        }
      });
      if (callback && typeof callback === "function") callback(this.editProductData);
    },
    // SET
    async setEditProductData() {
      let valid = true;
      if (this.editProductData.label === "") {
        $("#inputEditProductLabel").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.editProductData.unit === "") {
        $("#inputEditProductUnit").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.editProductData.unitWeight === "") {
        $("#inputEditProductUnitWeight").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.editProductData.volume.height === 0) {
        $("#inputEditProductHeight").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.editProductData.volume.width === 0) {
        $("#inputEditProductWidth").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.editProductData.volume.depth === 0) {
        $("#inputEditProductDepth").get()[0].classList.add("missing")
        valid = false;
      }

      if (!valid) {
        setTimeout(() => {
          $("#inputEditProductLabel").get()[0].classList.remove("missing")
          $("#inputEditProductAmount").get()[0].classList.remove("missing")
          $("#inputEditProductUnit").get()[0].classList.remove("missing")
          $("#inputEditProductUnitWeight").get()[0].classList.remove("missing")
          $("#inputEditProductHeight").get()[0].classList.remove("missing")
          $("#inputEditProductWidth").get()[0].classList.remove("missing")
          $("#inputEditProductDepth").get()[0].classList.remove("missing")
        }, 3000)
        return;
      }
      let response = await api.setProductData(this.editProductData);
      console.log(response);
      if (response["status-code"] === "200") {
        window.alert("El producto ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el producto.");
      }
      this.getEditProductData();
    },
    async setEditProductImage() {
      let response = await api.setProductImage(this.editProductImageForm);
      console.log(response);
      this.onRemoveEditProductImage();
      this.getEditProductData();
    }
  },
  created() {
    this.getEditProductData();
  }
}
</script>

<style>
#editProductModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

@media (max-width: 767px) {
  #productImageUploadFormContainer {
    flex-direction: column;
  }
}
</style>