<template>
<!-- SECTION Installation Charts -->
  <div id="ChartsSection" class="col-12 justify-content-center pe-3"
  :class="(visibility.sideMenu)?'section-container-sm' :'section-container-lg'">
    <div class="row col-12 p-0 m-0 bg-caja section-container rounded-5">

<!-- HEADER Installation Charts -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100" >
        <div class="col-lg-9 col-md-12 col-12 justify-content-center" style="display: inherit">
          <!--<ion-icon v-if="session.user.bookmark==='charts'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <h4 class="tx-left tx-clomos-white text-nowrap mb-2 ps-1 pt-2" style="height:40px;">

            <ion-icon name="stats-chart-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary["Gráficas"][session.user.language]}}
          </h4>
        </div>

<!-- Selector de Sistema -->
        <div v-if="this.$route.params.currentDisplay==='charts'"
        class="col-lg-3 col-md-12 col-12 p-2 pb-1 justify-content-center">	
          <select
          :value="currentCompany.nifCif"
          class="form-control h-100 text-nowrap bg-white" 
          style="border: solid 2px white ; color: black;"
          @change="onCurrentCompanyChanged">
            <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
            <option v-for="company in availableCompanies" :key="company.nifCif"
            :v-if="company.nifCif != session.user.nifCif
            && company.relation != undefined
            && company.relation.referenceCompany === this.session.user.nifCif"
            :value="company.nifCif">
              {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
            </option>
          </select>
        </div>
      </div>
      
      <div class="col-lg-6 col-md-6 col-12 d-flex flex-wrap p-2 pt-3 pb-0">

        <div class="col-lg-6 col-md-12 col-12 m-0 p-0 pe-2 ps-2" style="text-align: left;">
          <label class="col-12 m-0 p-0 tx-bold tx-clomos-midtone">
            {{dictionary['ID del dispositivo'][session.user.language]}}:
          </label>
          
          <select
          v-model="targetDeviceId"
          @change="getDeviceData"
          id="selectTargetDevice" data-live-search="true" class="form-control col-12 m-0 ps-1 text-nowrap" style="padding-bottom: 3px; color: black;">
            <option v-for="device in availableDevices" :key="device['id']" :value="device['id']" :selected="device['id'] === targetDeviceId">
              {{device['label']}}
            </option>
          </select>

        </div>
        
        <div class="col-lg-6 col-md-12 col-12 m-0 p-0 pe-2 ps-2" style="text-align: left;">
          <label class="col-12 m-0 p-0 tx-bold tx-clomos-midtone">
            {{dictionary['Variable'][session.user.language]}}:
          </label>

          <select
          v-model="targetVariableId"
          id="selectTargetDevice" data-live-search="true" class="form-control col-12 m-0 ps-1 text-nowrap" style="padding-bottom: 3px; color: black;">
            <option v-for="variable in historyVariables" :key="variable['variableId']" :value="variable['variableId']" :selected="variable['variableId'] === targetVariableId">
              {{variable['label']}}
            </option>
          </select>
        </div>
        
      </div>

      <div class="col-lg-6 col-md-6 col-12 d-flex flex-wrap p-2 pt-3 pb-0">

        <div class="col-lg-6 col-md-12 col-12 m-0 p-0 pe-2 ps-2" style="text-align: left;">
          <label class="col-12 m-0 p-0 tx-bold tx-clomos-midtone">
            {{dictionary['Fecha inicio'][session.user.language]}}:
          </label>
          <input v-model="startDateTimeInput"
          id="inputStartDate" :type="(session.browser === 'firefox') ?'date' :'datetime-local'" class="form-control form-control-line col-12 m-0 p-1" style="color: black;">
        </div>
        
        <div class="col-lg-6 col-md-12 col-12 m-0 p-0 pe-2 ps-2" style="text-align: left;">
          <label class="col-12 m-0 p-0 tx-bold tx-clomos-midtone">
            {{dictionary['Fecha fin'][session.user.language]}}:
          </label>
          <input v-model="endDateTimeInput"
          id="inputEndDate" :type="(session.browser === 'firefox') ?'date' :'datetime-local'" class="form-control form-control-line col-12 m-0 p-1" style="color: black;">
        </div>
        
      </div>

      <div class="col-lg-6 col-md-6 col-12 d-flex flex-wrap p-2 pt-3 pb-0 gap-2">
      
        <div class="col-md-12 col-12 m-0 p-0 tx-bold tx-clomos-midtone pe-2 ps-2 d-flex justify-content-between" style="text-align: left; vertical-align: center;">
          <label class="m-0 p-0">
            {{dictionary['Eje izquierdo'][session.user.language]}}:
          </label>
          <input v-model="checkboxTargetAxisLeft" @change="checkboxTargetAxisRight=!checkboxTargetAxisLeft"
          id="checkboxTargetAxisLeft" type="checkbox" style="width: 1.25rem" class="m-0 p-0 h-100">
        </div>
          
        <div class="col-md-12 col-12 m-0 p-0 tx-bold tx-clomos-midtone pe-2 ps-2 d-flex justify-content-between" style="text-align: left; vertical-align: center;">
          <label class="m-0 p-0">
            {{dictionary['Eje derecho'][session.user.language]}}:
          </label>
          <input v-model="checkboxTargetAxisRight" @change="checkboxTargetAxisLeft=!checkboxTargetAxisRight"
          id="checkboxTargetAxisRight" type="checkbox" style="width: 1.25rem" class="m-0 p-0 h-100">
        </div>
  
        
        <div class="col-md-12 col-12 m-0 p-0 tx-bold tx-clomos-midtone pe-2 ps-2 d-flex justify-content-between" style="text-align: left; vertical-align: center;">
          <label class="m-0 p-0">
            {{dictionary['Escalonado'][session.user.language]}}:
          </label>
          <input v-model="checkboxStep" @click="checkboxStep=!checkboxStep"
          id="checkboxStep" type="checkbox" style="width: 1.25rem" class="m-0 p-0 h-100">
        </div>

      </div>
      
      <div class="col-lg-6 col-md-6 col-12 d-flex flex-wrap p-0 m-0">

        <div class="col-12 m-0 p-3 pb-0" style="text-align: left;">
          <button type="button" @click="getDeviceVariableHistory(1)"
          class="btn btn-clomos w-100" id="btnGuardarPerfil">
            {{dictionary['Aplicar en gráfica'][session.user.language]}} 1
          </button>
        </div>
        
        <div class="col-12 m-0 p-0 p-3 pb-0" style="text-align: left;">
          <button type="button" @click="getDeviceVariableHistory(2)"
          class="btn btn-clomos w-100" id="btnGuardarPerfil">
            {{dictionary['Aplicar en gráfica'][session.user.language]}} 2
          </button>
        </div>

      </div>
          
      <div class="col-12 p-3 pb-0">
        <hr class="tx-clomos-dark m-0">
      </div>

<!-- CARD Chart 1 -->
      <div id="cardChart1" class="p-3 pb-0" :class="(chartSize['1'].max)?'col-12':'col-6'">

        <h5 class="bg-clomos-midtone rounded-top-3 p-1 mb-0 tx-clomos-white">
          {{dictionary['Gráfica'][session.user.language]}} 1
          <ion-icon 
          name="refresh-circle-outline" class="tx-27 pe-0 btn btnSizeChart" 
          @click="chartHistory['1']={}">
          </ion-icon>
          <ion-icon v-if="chartSize['1'].max"
          name="chevron-back-circle-outline" class="tx-27 pe-0 btn btnSizeChart" 
          @click="chartSize['1'].max=!chartSize['1'].max">
          </ion-icon>
          <ion-icon v-if="!chartSize['1'].max"
          name="chevron-forward-circle-outline" class="tx-27 pe-0 btn btnSizeChart" 
          @click="chartSize['1'].max=!chartSize['1'].max">
          </ion-icon>
        </h5>

        <div class="border-1 pt-3 pb-3 m-0">
          <VueEcharts :option="chart1Options"
          id="Chart1Canvas" class="col-12 w-100 ps-0 pe-0" style="height: 23.75rem;">
          </VueEcharts>
        </div>

      </div>

<!-- CARD Chart 2 -->
      <div id="cardChart2" class="p-3" :class="(chartSize['2'].max)?'col-12':'col-6'">
        <h5 class="bg-clomos-midtone rounded-top-3 p-1 mb-0 tx-clomos-white">
          {{dictionary['Gráfica'][session.user.language]}} 2
          <ion-icon 
          name="refresh-circle-outline" class="tx-27 pe-0 btn btnSizeChart" 
          @click="chartHistory['2']={}">
          </ion-icon>
          <ion-icon v-if="chartSize['2'].max"
          name="chevron-back-circle-outline" class="tx-27 pe-0 btn btnSizeChart" 
          @click="chartSize['2'].max=!chartSize['2'].max">
          </ion-icon>
          <ion-icon v-if="!chartSize['2'].max"
          name="chevron-forward-circle-outline" class="tx-27 pe-0 btn btnSizeChart" 
          @click="chartSize['2'].max=!chartSize['2'].max">
          </ion-icon>
        </h5>

        <div class="border-1 pt-3 pb-3 m-0">
          <VueEcharts :option="chart2Options"
          id="Chart2Canvas" class="col-12 w-100 ps-0 pe-0" style="height: 23.75rem;">
          </VueEcharts>
        </div>

      </div>

    </div>
  </div>

</template>

<script>
import $ from 'jquery';
import { VueEcharts } from 'vue3-echarts';
import echartsOptionsJson from '@/assets/target/json/echarts_options.json';
// Clomos
import api from '@/services/api';

export default {
  name: "ChartsComponent",
  components: { VueEcharts },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      await this.getDeviceListByCompany();
      if (this.availableDevices[0] != undefined) {
        this.targetDeviceId = this.availableDevices[0].id;
        await this.getDeviceData();
      }
      else {
        this.targetDeviceId = ""
      }
      if (Object.entries(this.historyVariables)[0] != undefined) {
        this.targetVariableId = Object.entries(this.historyVariables)[0][0];
      }
      else{
        this.targetVariableId = ""
      }
    },
  },
  data() {
    return {
      // LAST DAY CHART
      lineChartOptions: JSON.parse(JSON.stringify(echartsOptionsJson))["lineChartOptions"],
      deviceData : {
        id: "",
        nifCif: "",
        dataConsumed: "",
        label: "",
        dclId: "",
        dclType: "",
        dclClass: "",
        typeName: "",
        distributionGroup: "",
        parent: "",
        isBase: "",
        isPublic: "",
        token: "",
        imgSrc: "",
        emplacement: "",
        position: "",
        fechaUltimaComunicacion: "",
        tieneAlarmaActivada: "",
        version: "",
        variables: {
          analog: {},
          digital: {},
          dynamic: {}
        }
      },
      availableDevices: [],
      targetDeviceId: "",
      targetVariableId: "",
      checkboxTargetAxisLeft: true,
      checkboxTargetAxisRight: false,
      checkboxStep: false,
      startDateTimeInput: api.parseDateTimeToString(new Date().setDate(new Date().getDate() - 1)),
      endDateTimeInput: api.parseDateTimeToString(new Date()),
      startDateTime: {
        "1": "",
        "2": "",
      },
      endDateTime: {
        "1": "",
        "2": "",
      },
      chartHistory: {
        "1": {},
        "2": {}
      },
      chartSize: {
        "1": { max: true },
        "2": { max: true }
      },
      // COLORS
      colors: ["#001e76", "#e96526", "#0086ff", "#eda37f"]
    }
  },
  computed: {
    fractionalLength() {
      return (localStorage.getItem("ClomosFractionalLength")!=undefined)?localStorage.getItem("ClomosFractionalLength"):2;
    },
    historyVariables() {
      let historyVariables = {};
      for (let variableType in this.deviceData.variables)
        for (let variable in this.deviceData.variables[variableType])
          if (this.deviceData.variables[variableType][variable].saveHistory==='true')
            historyVariables[this.deviceData.variables[variableType][variable].variableId] = this.deviceData.variables[variableType][variable];
      return historyVariables;
    },
    chart1Options() {
      let chart1Options;
      chart1Options = JSON.parse(JSON.stringify(this.lineChartOptions));
      if (this.targetDeviceId == "") return chart1Options;
      chart1Options.axisPointer = {
        label: {
          formatter: ((dataTarget) => {
            let d = new Date(dataTarget.value);
            switch (this.session.user.dateTimeFormat) {
              case "DD/MM/YYYY": 
                return String(d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear()+" "+d.getHours()).padStart(2,"0")+":"+String(d.getMinutes()).padStart(2,"0");
              case "MM/DD/YYYY": 
                return String((d.getMonth()+1)+"/"+d.getDate()+"/"+d.getFullYear()+" "+d.getHours()).padStart(2,"0")+":"+String(d.getMinutes()).padStart(2,"0");
              default:
                return String((d.getMonth()+1)+"/"+d.getDate()+"/"+d.getFullYear()+" "+d.getHours()).padStart(2,"0")+":"+String(d.getMinutes()).padStart(2,"0");
            }
          })
        },
      }
      chart1Options.xAxis = {
        min: new Date(this.startDateTime["1"]).getTime(),
        max: new Date(this.endDateTime["1"]).getTime(),
        axisLabel: {
          formatter: ((value) => {
            let d = new Date(value);
            switch (this.session.user.dateTimeFormat) {
              case "DD/MM/YYYY": 
                return String(d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear()+" "+d.getHours()).padStart(2,"0")+":"+String(d.getMinutes()).padStart(2,"0");
              case "MM/DD/YYYY": 
                return String((d.getMonth()+1)+"/"+d.getDate()+"/"+d.getFullYear()+" "+d.getHours()).padStart(2,"0")+":"+String(d.getMinutes()).padStart(2,"0");
              default:
                return String((d.getMonth()+1)+"/"+d.getDate()+"/"+d.getFullYear()+" "+d.getHours()).padStart(2,"0")+":"+String(d.getMinutes()).padStart(2,"0");
            }
          })
        }
      }
      let i = 0;
      for (let variable in this.chartHistory["1"]) {
        chart1Options.yAxis[i] = {
          type: "value",
          name: variable.substring(0,3).toUpperCase(),
          offset: Math.floor(i/2)*30,
          position: (this.chartHistory["1"][variable][i]!=undefined)
            ?this.chartHistory["1"][variable][i][2]  
              :(i%2===0)
                ?"left"
                :"right"
        }
        chart1Options.legend.data[i] = variable;
        chart1Options.series[i] = {
          data : this.chartHistory["1"][variable],
          name : variable,
          yAxisIndex: i,
          type: "line",
          color : this.colors[i],
          smooth : true,
          step : (this.chartHistory["1"][variable][i] != undefined) ?this.chartHistory["1"][variable][i][3] :false,
        }
        i+=1;
      }
      return chart1Options;
    },
    chart2Options() {
      let chart2Options;
      chart2Options = JSON.parse(JSON.stringify(this.lineChartOptions));
      if (this.targetDeviceId == "") return chart2Options;
      chart2Options.axisPointer = {
        label: {
          formatter: ((dataTarget) => {
            let d = new Date(dataTarget.value);
            switch (this.session.user.dateTimeFormat) {
              case "DD/MM/YYYY": 
                return String(d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear()+" "+d.getHours()).padStart(2,"0")+":"+String(d.getMinutes()).padStart(2,"0");
              case "MM/DD/YYYY": 
                return String((d.getMonth()+1)+"/"+d.getDate()+"/"+d.getFullYear()+" "+d.getHours()).padStart(2,"0")+":"+String(d.getMinutes()).padStart(2,"0");
              default:
                return String((d.getMonth()+1)+"/"+d.getDate()+"/"+d.getFullYear()+" "+d.getHours()).padStart(2,"0")+":"+String(d.getMinutes()).padStart(2,"0");
            }
          })
        },
      }
      chart2Options.xAxis = {
        min: new Date(this.startDateTime["2"]).getTime(),
        max: new Date(this.endDateTime["2"]).getTime(),
        axisLabel: {
          formatter: ((value) => {
            let d = new Date(value);
            switch (this.session.user.dateTimeFormat) {
              case "DD/MM/YYYY": 
              return String(d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear()+" "+d.getHours()).padStart(2,"0")+":"+String(d.getMinutes()).padStart(2,"0");
              case "MM/DD/YYYY": 
              return String((d.getMonth()+1)+"/"+d.getDate()+"/"+d.getFullYear()+" "+d.getHours()).padStart(2,"0")+":"+String(d.getMinutes()).padStart(2,"0");
              default:
                return String((d.getMonth()+1)+"/"+d.getDate()+"/"+d.getFullYear()+" "+d.getHours()).padStart(2,"0")+":"+String(d.getMinutes()).padStart(2,"0");
            }
          })
        },
      }
      let i = 0;
      for (let variable in this.chartHistory["2"]) {
        chart2Options.yAxis[i] = {
          type: "value",
          name: variable.substring(0,3).toUpperCase(),
          offset: Math.floor(i/2)*30,
          position: (this.chartHistory["2"][variable][i]!=undefined)
            ?this.chartHistory["2"][variable][i][2]  
              :(i%2===0)
                ?"left"
                :"right"    
        }
        chart2Options.legend.data[i] = variable;
        chart2Options.series[i] = {
          data : this.chartHistory["2"][variable],
          name : variable,
          yAxisIndex: i,
          type: "line",
          color : this.colors[i],
          smooth : true,
          step : (this.chartHistory["2"][variable][i] != undefined) ?this.chartHistory["2"][variable][i][3] :false,
        }
        i+=1;
      }
      return chart2Options;
    }
  },
  emits: ["setCurrentCompany"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    // GET
    async getDeviceData(callback) {
      let response = await api.getDeviceData(this.targetDeviceId);
      this.deviceData = api.parseDeviceFromResponse(response);
      if (Object.entries(this.historyVariables)[0] != undefined) {
        this.targetVariableId = Object.entries(this.historyVariables)[0][0];
      }
      else{
        this.targetVariableId = ""
      }
      if (callback && typeof callback === "function") callback(this.deviceData);
    },
    async getDeviceListByCompany(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getDeviceListByCompany(request);
      this.availableDevices = api.parseDevicesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.availableDevices);
    },
    async getDeviceVariableHistory(n, callback) {
      let targetAxis = (this.checkboxTargetAxisRight)? 'right' :'left';
      let step = (this.checkboxStep)? true :false;
      if (this.startDateTimeInput === "" || this.endDateTimeInput === "") {
        if (this.startDateTimeInput === "")
          $("#inputStartDate").get()[0].classList.add("missing")
        if (this.endDateTimeInput === "")
          $("#inputEndDate").get()[0].classList.add("missing")
        setTimeout(() => {
          $("#inputStartDate").get()[0].classList.remove("missing")
          $("#inputEndDate").get()[0].classList.remove("missing")
        }, 3000)
        return;
      }
      if (this.deviceData != {}) {
        let request = {
          id: this.deviceData.id,
          variableLabel: this.historyVariables[this.targetVariableId].label,
          variableType: this.historyVariables[this.targetVariableId].type,
          limit: 0,
          offset: 0,  
          startDateTime: api.parseDateTimeToString(this.startDateTimeInput),
          endDateTime: api.parseDateTimeToString(this.endDateTimeInput)
        }
        let response = await api.getDeviceVariableHistory(request);
        // PARSE HISTORY RESPONSE
        let rawHistory = api.parseVariableHistoryFromResponse(response);
        this.chartHistory[n][this.historyVariables[this.targetVariableId].label+"-"+this.targetDeviceId] = [];
        for (let i in rawHistory) {
          this.chartHistory[n][this.historyVariables[this.targetVariableId].label+"-"+this.targetDeviceId][i] = [
            // DATE
            this.$dayjs(new Date(rawHistory[i][0])).utc("z").local().tz(this.session.user.dateTimeZone).$d,
            // VALUE
            new Number(rawHistory[i][1]).toFixed(this.fractionalLength),
            // POSITION
            targetAxis,
            // STEP
            step,
          ]
        }
        // SET DATA BOUNDRIES
        this.startDateTime[n] = this.startDateTimeInput;
        this.endDateTime[n] = this.endDateTimeInput;
        // SWITCH AXIS TARGET
        this.checkboxTargetAxisRight=!this.checkboxTargetAxisRight;
        this.checkboxTargetAxisLeft=!this.checkboxTargetAxisRight;
        if (callback && typeof callback === "function") callback(this.availableDevices)
      }
    }
  },
  async created() {
    await this.getDeviceListByCompany();
    if (this.availableDevices[0] != undefined) {
      this.targetDeviceId = this.availableDevices[0].id;
      await this.getDeviceData();
    }
    else {
      this.targetDeviceId = ""
    }
    if (Object.entries(this.historyVariables)[0] != undefined) {
      this.targetVariableId = Object.entries(this.historyVariables)[0][0];
    }
    else{
      this.targetVariableId = ""
    }
  }
}
</script>

<style>
  #cardRelaysChartsContainer {
    display: flex;
    gap: 1rem;
  }
  #cardDigitalInputsContainer {
    display: flex;
    gap: 1rem;
  }
  .btnSizeChart:hover {
  color: var(--main-dark);
}
@media (max-width: 992px) {
  #cardOverall, #cardCurrentCharts, #cardSCC {
    padding-right: 1rem !important;
  }
  #cardRelaysChartsContainer, #cardDigitalInputsContainer {
    display: block;
  }
}
</style>