<template>
<!-- MODAL Link Device -->
  <div id="textAlertModal"
  class="col-12 justify-content-center pe-6"
  style="padding: 15vh 20%">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">

      <div class="form-group col-12 p-3">
        <div class="d-flex col-12">
          <span class="me-3 tx-bold tx-clomos-midtone">
            {{dictionary['Acción'][session.user.language]+": " }}
          </span>
          <span>
            {{ (linkAction==='link') ?dictionary['Vincular'][session.user.language]:dictionary['Desvincular'][session.user.language] }}
          </span>
        </div>

        <div class="d-flex col-12">
          <span class="me-3 tx-bold tx-clomos-midtone">
            {{ dictionary['Dispositivo'][session.user.language]+": " }}
          </span>
          <span class="text-nowrap">
            {{ targetDeviceId }}
          </span>
        </div>

        <div v-if="linkAction === 'link'" 
        class="d-flex col-12">
          <span class="me-3 tx-bold tx-clomos-midtone">
            {{dictionary['Empresa'][session.user.language]+": " }}
          </span>
          <select class="w-100 m-auto pt-0 pb-0 h-100 tx-clomos-black text-nowrap"
          :value="targetEmplacementId"
          @change="targetEmplacement">
            <option v-for="emplacement in availableEmplacements" :key="emplacement"
            :value="emplacement.id">
              {{ emplacement.label }}
            </option>
          </select>
        </div>

        <div class="row">

          <div class="col-lg-6 col-md-6 col-12 mt-3">
  <!-- SUBMIT -->
            <button type="button" id="btnSubmitCreateNewEmplacement" class="btn btn-clomos w-100"
            @click="linkDeviceToEmplacement">
            {{dictionary['Aceptar'][session.user.language]}}
            </button>
          </div>

          <div class="col-lg-6 col-md-6 col-12 mt-3">
  <!-- DISMISS -->
            <button type="button" id="btnSubmitCreateNewEmplacement" class="btn btn-clomos w-100"
            @click="this.$emit('dismissAlert')">
            {{dictionary['Cancelar'][session.user.language]}}
            </button>
          </div>

        </div>
      </div>

    </div>
  </div>

</template>

<script>
// Clomos
import api from '@/services/api';

export default {
  name: "LinkDeviceModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    targetDeviceId: {
      type: String,
      requiered: true,
    },
    linkAction: {
      type: String,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
  },
  data() {
    return {
      targetEmplacementId: "",
      availableEmplacements: []
    }
  },
  methods: {
    // GET
    async getEmplacementList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getEmplacementList(request);
      this.availableEmplacements = api.parseEmplacementsFromResponse(response);
      if (callback && typeof callback === "function") callback(this.availableEmplacements);
    },
    // TARGET
    targetEmplacement(targetEmplacementId) {
      this.targetEmplacementId = targetEmplacementId.target.value;
    },
    // SET 
    async linkDeviceToEmplacement(callback) {
      let request = {
        deviceId : this.targetDeviceId,
        emplacementId : this.targetEmplacementId
      }
      let response = await api.setDeviceParent(request);
      if (response["status-code"]==="200") this.$emit('dismissAlert');
      if (callback && typeof callback === "function") callback(this.availableEmplacements);
    }
  },
  async created() {
    if (this.linkAction === "link") {
      await this.getEmplacementList();
      if (this.availableEmplacements[0]!=undefined)
        this.targetEmplacementId = this.availableEmplacements[0].id;
    }
    else 
      this.targetEmplacementId = "";
  }
}
</script>

<style>
#textAlertModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>