<template>
<!-- MODAL Execute Expedition -->
  <div
  id="prepareExpeditionModal"
  class="col-12 justify-content-center-content-center pe-6"
  style="padding: 15vh 20%;">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">
      
      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100" >

        <div class="col-10" style="display: inherit">
          <h4 class="tx-left tx-clomos-white mb-2 ps-1 pt-2">
            <ion-icon name="arrow-redo-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary["Preparación"][session.user.language]}}
          </h4>
        </div>

        <div class="col-2 m-0 p-0" style="display: flex; justify-content-center-content: end;"
        @click="$emit('togglePrepareExpeditionModal')">	
          <ion-icon name="close" class="ms-auto tx-46 tx-white align-bottom"></ion-icon>
        </div>
      </div>

      <div class="form-group col-12 p-3">
        
        <div class="p-3 border-1" style="overflow: scroll; max-height: 60vh;">

          <div v-if="expeditionData.params != undefined" id="expeditionCheckListContainer">
            
            <div class="col-12 p-0 m-0">
              <label class="col-12 tx-bold tx-clomos-dark text-nowrap ps-3 pe-3">
                {{dictionary['Orden'][session.user.language]}}
              </label>
            </div>

            <table id="expeditionCheckList" cellspacing="0" border="0" class="col-12 m-0 p-0">
              <tr class="border-bottom">
                <td></td>
                <td class="tx-bold"> {{dictionary['Referencia'][session.user.language]}} </td>
                <td class="tx-bold"> {{dictionary['Nombre'][session.user.language]}} </td>
                <td class="tx-bold"> {{dictionary['Talla'][session.user.language]}} </td>
                <td class="tx-bold"> {{dictionary['Color'][session.user.language]}} </td>
                <td class="tx-bold"> {{dictionary['Caducidad'][session.user.language]}} </td>
                <td class="tx-bold"> {{dictionary['Zona'][session.user.language]}} </td>
                <td class="tx-bold"> {{dictionary['Unidades'][session.user.language]}} </td>
                <td class="tx-bold"> {{dictionary['Pedir'][session.user.language]}} </td>
                <td class="tx-bold"> {{dictionary['Cancelar'][session.user.language]}} </td>
                <td class="tx-bold"> 
                  {{dictionary['Cajas'][session.user.language]}}
                  <ion-icon name="add-circle-outline" class="tx-clomos-dark targets-icons m-0 p-0 tx-24" style="vertical-align: middle;"
                  @click="addBulge()">
                  </ion-icon>
                  <ion-icon name="remove-circle-outline" class="tx-clomos-dark targets-icons m-0 p-0 tx-24" style="vertical-align: middle;"
                  @click="removeBulge()">
                  </ion-icon>
                </td>
                <!-- <td class="tx-bold">Listo</td> -->
              </tr>
              
              <tr class="border-bottom" v-for="product of expeditionData.params['productos']" :key="product">
                <td class="text-nowrap p-1"> <input class="productCheckbox" type="checkbox"> </td>
                <td class="text-nowrap p-1"> {{(product.referencia!=undefined)?product.referencia:'-'}} </td>
                <td class="text-nowrap p-1"> {{(product.etiqueta!=undefined)?product.etiqueta:'-'}}</td>
                <td class="text-nowrap p-1"> {{(product.talla!=undefined)?product.talla:'-'}}</td>
                <td class="text-nowrap p-1"> {{(product.color!=undefined)?product.color:'-'}}</td>
                <td class="text-nowrap p-1"> {{(product.fechaCaducidad!=undefined)?product.fechaCaducidad:'-'}}</td>
                <td class="text-nowrap p-1"> {{(product.zona!=undefined)?product.zona:'-'}}</td>
                <td class="text-nowrap p-1"> {{(product.unidades!=undefined)?product.unidades:'-'}}</td>
                <td class="text-nowrap p-1"> <ion-icon name="enter-outline" class="tx-clomos-dark btn order-button m-0 p-0 tx-24" style="vertical-align: middle;"
                @click="''">
                </ion-icon></td>
                <td> <ion-icon name="close-circle-outline" class="tx-clomos-dark btn order-button-disabled m-0 p-0 tx-24"
                @click="''">
                </ion-icon></td>
                <td style="display:flex; flex-direction:row; flex-flow:wrap; gap:0.3rem; justify-content-center-content:left; width:330px;">
                  <div v-for="bulge, index in bulgeList" :key="bulge" 
                  class="m-0 p-0">
                    
                    <div 
                    class="m-0 border-clomos rounded-3 p-1" 
                    style="justify-content-center-content: space-around; width: 100%; white-space:nowrap">
                      <span class="tx-14 tx-bold tx-clomos-dark">
                      {{ (index+'').padStart(2,'0') }}
                      </span>
                      <span style="position:relative; left:60px; bottom: 0px; z-index: 10;" class="tx-clomos-midtone">
                      {{ "u" }}
                      </span>
                      <input v-model="product.bultos[index]" type="number" min=0 :max="product.unidades" placeholder=0
                      class="inputAmmountPerBulge border-2 rounded-1 text-center"
                      :class="(isBulgeEmpty(index))?'missing':''">
                    </div>

                  </div>
                </td>
              </tr>

              <tr>
                <td> <div @click="selectAllProducts()" class="bg-clomos rounded-3 p-1 text-wrap " style="word-spacing: 100vw; margin: auto; cursor: pointer;"> <span class="tx-white tx-bold m-0 p-0 tx-12"> 
                  {{dictionary["Marcar todos"][session.user.language]}}
                </span> </div> </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td> <div @click="''" class="bg-clomos rounded-3 p-1 text-wrap " style="word-spacing: 100vw; margin: auto; cursor: pointer;"> <span class="tx-white tx-bold m-0 p-0 tx-12"> 
                  {{dictionary["Pedir marcados"][session.user.language]}}
                </span> </div> </td>
                <td> <div @click="''" class="bg-clomos rounded-3 p-1 text-wrap " style="word-spacing: 100vw; margin: auto; cursor: pointer;"> <span class="tx-white tx-bold m-0 p-0 tx-12"> 
                  {{dictionary["Pedir todos"][session.user.language]}}
                </span> </div> </td>
                <td> <div @click="''" class="bg-clomos rounded-3 p-1 text-wrap " style="word-spacing: 100vw; margin: auto; cursor: pointer;"> <span class="tx-white tx-bold m-0 p-0 tx-12"> 
                  {{dictionary["Cancelar todos"][session.user.language]}}
                </span> </div> </td>
                <td> <div @click="assignBulgeAll()" class="bg-clomos rounded-3 p-1 text-wrap d-flex justify-content-center" style="width:330px; word-spacing: 100vw; margin: auto; cursor: pointer;"> <div class="w-25"> <span class="tx-white tx-bold m-0 p-0 tx-12"> 
                  {{dictionary["Asignar todos"][session.user.language]}}
                </span> </div> </div> </td>
              </tr>
            </table>

          </div>

          <div class="col-12 p-0 m-0">
            <hr class="tx-clomos-dark">
          </div>
          
          <div class="col-12 p-0 m-0">
            <label class="col-12 tx-bold tx-clomos-dark text-nowrap ps-3 pe-3">
              {{dictionary['Cajas'][session.user.language]}}
            </label>
          </div>

          <div class="col-lg-10 col-12 row p-0 m-0">
            <div class="d-flex">
<!-- BULTO -->
              <div class="col-2 p-1 text-center">
                <label class="tx-13 tx-bold tx-clomos-black text-nowrap p-0">
                  {{dictionary['Bulto'][session.user.language]}}
                </label>
              </div>

<!-- TIPO -->
              <div class="col-6 p-1 text-center">
                <label class="tx-13 tx-bold tx-clomos-black text-nowrap p-0">
                  {{dictionary['Tipo'][session.user.language]}}
                </label>
              </div>

<!-- TOTAL REFERENCIAS -->
              <div class="col-2 p-1 text-center">
                <label class="tx-13 tx-bold tx-clomos-black text-nowrap p-0">
                  {{dictionary['Total'][session.user.language]}}
                </label>
              </div>

<!-- TOTAL UNIDADES -->
              <div class="col-2 p-1 text-center">
                <label class="tx-13 tx-bold tx-clomos-black text-nowrap p-0">
                  {{dictionary['Total'][session.user.language]}}
                </label>
              </div>

            </div>

            <div class="d-flex" v-for="(bulge,index) in bulgeList" :key="bulge">
<!-- BULTO -->
              <div class="col-2 p-1">
                <div class="tx-20 tx-bold tx-clomos-dark pb-1">
                {{ (index+'').padStart(2,'0') }}
                </div>
              </div>

<!-- TIPO -->
              <div class="col-6 p-1">
                  <select v-model="bulgeList[index].boxType" :id="'inputBulgeType'+index" 
                  class="form-control form-control-line pt-2 pb-2 w-100" style="color:black;"
                  @change="assignBoxType(index)">
                    <option class="text-center"  v-for="boxType in boxTypes" :key="boxType" :value="boxType.id"> {{ boxType.id + ' ' + boxType.volume.width + 'x' + boxType.volume.height + 'x' + boxType.volume.depth+'(mm)' }} </option>
                  </select>
              </div>

<!-- TOTAL REFERENCIAS -->
              <div class="col-2 p-1">
                <span type="number" min=0 disabled class="tx-12" style="color:black;">
                  {{ totalProductsPerBulge(index) }}
                </span>
              </div>

<!-- TOTAL UNIDADES -->
              <div class="col-2 p-1">
                <span type="number" min=0 disabled class="tx-12" style="color:black;">
                  {{ totalUnitsPerBulge(index) }}
                </span>
              </div>

            </div>
            
          </div>
  
          <div class="w-100 m-0 p-0 pt-3 justify-content-center">

<!-- SUBMIT -->
            <button type="button" id="btnSubmitExpedition" class="btn btn-clomos w-50"
            @click="this.prepareExpedition()">
            {{dictionary['Preparar'][session.user.language]}}
            </button>

          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import boxTypesJson from "/src/assets/target/json//box_types.json";
// Clomos
import api from '@/services/api';

export default {
  name: "ExpeditionsComponent",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    },
    expeditionId: {
      type: String,
      requiered: true,
    },
  },
  data() {
    return {
      boxTypes: JSON.parse(JSON.stringify(boxTypesJson)),
      expeditionData: {},
      bulgeModel: {
        boxType: "",
        volume: {
          height: 0,
          width: 0,
          depth: 0
        },
      },
      bulgeList: [
        {
          boxType: "",
          volume: {
            height: 0,
            width: 0,
            depth: 0
          },
        }
      ],
    }
  },
  emits: ["togglePrepareExpeditionModal", "setCurrentCompany"],
  computed: {
    totalBulges() {
      let totalBulges = {};
      for (let i in this.bulgeList) {
        if (totalBulges[this.bulgeList[i].boxType] === undefined)
          
          totalBulges[this.bulgeList[i].boxType] = {
            volume: {
              height: this.bulgeList[i].volume.height,
              width: this.bulgeList[i].volume.width,
              depth: this.bulgeList[i].volume.depth
            },
            total: 1
          }

        else
          totalBulges[this.bulgeList[i].boxType].total += 1;

      }
      return totalBulges;
    }
  },
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    addBulge() {
        this.bulgeList.push(JSON.parse(JSON.stringify({...this.bulgeModel, id: this.bulgeList.length})));
        this.bulgeList[this.bulgeList.length-1].boxType = this.boxTypes[0].id;
        this.assignBoxType(this.bulgeList.length-1);
    },
    removeBulge() {
      if (this.bulgeList[1] === undefined) return;
      for (let p in this.expeditionData.params['productos']) {
        delete this.expeditionData.params['productos'][p].bultos[this.bulgeList.length-1]
      }
      this.bulgeList.pop();
    },
    selectAllProducts() {
      $('.productCheckbox').get().forEach((target) =>{target.checked = true});
    },
    assignBulgeAll() {
      if (this.bulgeList.length>0) for (let i in this.expeditionData.params['productos']) {
        this.expeditionData.params['productos'][i].bultos[0] = this.expeditionData.params['productos'][i].unidades;
      }
    },
    assignBoxType(index) {
      for (let i in this.boxTypes) {
        if (this.boxTypes[i].id === this.bulgeList[index].boxType) {
          this.bulgeList[index].volume = this.boxTypes[i].volume;
          return;
        }
      }
      this.prepareExpedition();
    },
    totalProductsPerBulge(index) {
      let products = 0;
      if (this.expeditionData.params != undefined) for (let product in this.expeditionData.params['productos']) {
        if (this.expeditionData.params['productos'][product]['bultos'] != undefined
        && this.expeditionData.params['productos'][product]['bultos'][index] != undefined) {
          products += 1
        }
      }
      return products;
    },
    totalUnitsPerBulge(index) {
      let units = 0;
      if (this.expeditionData.params != undefined) for (let product in this.expeditionData.params['productos']) {
        if (this.expeditionData.params['productos'][product]['bultos'] != undefined
        && this.expeditionData.params['productos'][product]['bultos'][index] != undefined) {
          units += this.expeditionData.params['productos'][product]['bultos'][index]
        }
      }
      return units;
    },
    isBulgeEmpty(index) {
      let isEmpty = true; 
      for (let product in this.expeditionData.params['productos']) {
        if (this.expeditionData.params['productos'][product]['bultos'] != undefined 
        && this.expeditionData.params['productos'][product]['bultos'][index] != undefined )
          isEmpty = false;
      }
      return isEmpty;
    },
    // GET
    async getExpeditionData(callback) {
      let response = await api.getOrderData(this.expeditionId);
      this.expeditionData = api.parseOrderFromResponse(response);
      this.expeditionData.userId = this.session.user.id;
      if (callback && typeof callback === "function") callback(this.expeditionData);
    },
    // SET
    async prepareExpedition() {
      let valid = true;
      for (let i in this.bulgeList) {
        if (this.bulgeList[i].boxType === "") {
          $("#inputBulgeType"+i).get()[0].classList.add("missing")
          valid = false;
        }
      }
      if (!valid) {
        setTimeout(() => {
          for (let i in this.bulgeList) {
            $("#inputBulgeType"+i).get()[0].classList.remove("missing")
          }
        }, 3000)
        return;
      }
      this.expeditionData.params['bultos'] = [];
      for (let i in this.bulgeList)
        this.expeditionData.params['bultos'][i] = {
          "ancho": this.totalBulges[this.bulgeList[i].boxType].volume.width,
          "alto": this.totalBulges[this.bulgeList[i].boxType].volume.height,
          "fondo": this.totalBulges[this.bulgeList[i].boxType].volume.depth,
        }
      if (this.expeditionData.executionDateTime === "") {
        this.expeditionData.executionDateTime = new Date();
      }

      let response = await api.setOrderData(this.expeditionData);
      console.log(response);
      if (response["status-code"] === "200" || response["status-code"] === "304") {
        let statusData = {
          orderId : this.expeditionId,
          dateTime : new Date(),
          status : 3
        }
        response = await api.setOrderStatus(statusData);
        console.log(response);
        if (response["status-code"] === "200") {
          window.alert("La expedición ha sido preparada satisfactoriamente.");
          this.$emit('togglePrepareExpeditionModal');
        }
      }
      else {
        window.alert("Error al preparar la expedición.");
      }
    },
  },
  async created() {
    this.bulgeList[0].boxType = this.boxTypes[0].id;
    this.assignBoxType(0);
    await this.getExpeditionData();
    if (this.expeditionData.status === "1") {
      let statusData = {
        orderId : this.expeditionId,
        dateTime : new Date(),
        status : 2
      }
      let response = await api.setOrderStatus(statusData);
      console.log(response);
    }
  }
}
</script>
<style>
#prepareExpeditionModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.inputAmmountPerBulge {
  color:black;
  background:none;
  min-width:65px;
  max-width:65px;
  padding: 3px 15px 1px 1px;
}

.inputAmmountPerBulge.missing {
  box-shadow: inset 0px 0px 0px 2px #ce7d0a;
}

.inputAmmountPerBulge::-webkit-outer-spin-button,
.inputAmmountPerBulge::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputAmmountPerBulge[type=number] {
  -moz-appearance: textfield;
}

.order-button {
  color: var(--main-dark);
}

.order-button:hover {
  color: var(--main-dark-50);
}

.order-button-disabled {
  cursor: none;
  color: var(--main-dark-50);
  pointer-events: none
  ;
}
</style>