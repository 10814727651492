<template>
<!-- MODAL Edit User -->
  <div id="editUserModal"
  class="col-12 justify-content-center pe-6"
  style="padding: 15vh 20%">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">
<!-- HEAD -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100">
        <div class="col-10">
          <h4 class="tx-left tx-clomos-white mb-2 ps-1 pt-2">
            <ion-icon name="person" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary['Editar usuario'][session.user.language]+": "+editUserData.name+" "+editUserData.lastName}}
          </h4>
        </div>

        <div class="col-2 m-0 p-0" style="display: flex; justify-content: end;"
        @click="$emit('toggleEditUserModal')">	
          <ion-icon name="close" class="ms-auto tx-46 tx-white align-bottom"></ion-icon>
        </div>

      </div>

      <div class="col-12 p-0 w-100">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="card-body">

<!-- FORM Password -->
            <div id="cardUserChangePassword" class="col-12 m-0 p-0 pb-3 overflow-hidden">

              <form class="form-material bg-clomos-midtone rounded">
                <div class="container-fluid p-0">
                  <div class="row p-0">

                    <div class="form-group col-lg-4 col-12 p-1 ps-2 pe-2">
                      <label class="col-12 tx-bold tx-white text-nowrap">
                        {{dictionary['Nueva contraseña'][session.user.language]}}
                      </label>
                      <div class="col-12">
                        <input v-model="changePassData.newPassword" id="inputEditUserNewPassword" type="password" autocomplete="off" 
                        class="form-control form-control-line" style="color:black; background-color: #EEEEEE">
                      </div>
                    </div>

                    <div class="form-group col-lg-4 col-12 p-1 ps-2 pe-2">
                      <label class="col-12 tx-bold tx-white text-nowrap">
                        {{dictionary['Repite la contraseña'][session.user.language]}}
                      </label>
                      <div class="col-12">
                        <input v-model="changePassData.newPasswordCheck" id="inputEditUserNewPasswordChe" type="password" autocomplete="off" 
                        class="form-control form-control-line" style="color:black; background-color: #EEEEEE">
                      </div>
                    </div>

                    <div class="form-group col-lg-4 col-12 p-1 ps-2 pe-2">
                      <div class="col-12 mt-4 mb-4">
                        <button type="button" @click="onChangePassword"
                        class="btn btn-dark w-100" id="btnGuardarPass">
                          {{dictionary['Restablecer contraseña'][session.user.language]}}
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </form>
            </div>

<!-- FORM User -->
            <form class="form-material border-1 p-3 pt-0 overflow-hidden">

              <div class="container-fluid p-3">
                
                <div class="row">
                  
<!-- NOMBRE -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Nombre'][session.user.language]}} *
                    </label>
                    <div class="col-md-12">
                      <input v-model="editUserData.name" id="inputEditUserName" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

<!-- APELLIDOS -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Apellidos'][session.user.language]}} *
                    </label>
                    <div class="col-md-12">
                      <input v-model="editUserData.lastName" id="inputEditUserLastName" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

                </div>
              
                <div class="row">
                
<!-- CORREO -->
                  <div class="form-group col-md-12 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Correo electrónico'][session.user.language]}} *
                    </label>
                    <div class="col-md-12">
                      <input v-model="editUserData.email" id="inputEditUserEmail" disabled type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>
                
                </div>

                
<!-- CORREO RE -->
<!--
                <div class="row">
                  <div class="form-group col-md-12 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Repetir correo'][session.user.language]}} *
                    </label>
                    <div class="col-md-12">
                      <input v-model="editUserData.emailRe" id="inputEditUserEmailRe" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>
                
                </div>
-->
                <div class="row">
                 
<!-- ROL -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Rol'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <select v-model="editUserData.rol" disabled
                      id="selectEditUserRol"
                      class="form-control form-control-line" style="color:black;">
                        <option value="255"> 
                          {{dictionary['Administrador'][session.user.language]}}
                        </option>
                        <option value="128">
                          {{this.dictionary['Administrador de la empresa'][this.session.user.language]}}
                        </option>
                        <option value="32">
                          {{this.dictionary['Autorizado'][this.session.user.language]+"/"+this.dictionary['Editor'][this.session.user.language]}}
                        </option>
                        <option value="2">
                          {{dictionary['Cliente'][session.user.language]}}
                        </option>
                        <option value="1">
                          {{dictionary['Analista'][session.user.language]}}
                        </option>
                      </select>
                    </div>
                  </div>
                
<!-- TELÉFONO -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Teléfono'][session.user.language]}}
                    </label>
                    <div class="col-12 d-flex flex-row">
                      <div class="w-100">
                        <input v-model="editUserData.telephone" id="inputEditUserTelephone"
                        class="form-control form-control-line" style="color:black;">
                      </div>
                    </div>
                      
                  </div>

                </div>

                <div class="row">

<!-- PROVINCIA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Provincia'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input v-model="editUserData.province" id="inputEditUserState" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

<!-- PAÍS -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['País'][session.user.language]}}
                    </label>
                    <div class="col-sm-12">
                      <select v-model="editUserData.country"
                      id="selectEditUserCountry"
                      class="form-control form-control-line" style="color:black;">
                        <option v-for="country in countries" :key="country['code']" :value="country['code']">
                          {{country['name'][session.user.language]}}
                        </option>
                      </select>
                    </div>
                  </div>

                </div>

                <div class="row">

<!-- DIRECCIÓN -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Dirección'][session.user.language]}} 1
                    </label>
                    <div class="col-12">
                      <textarea v-model="editUserData.adress1" id="inputEditUserAdress1" rows="1"
                      class="form-control" style="color: black; padding: 0.7rem;"/>
                    </div>
                  </div>

                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Dirección'][session.user.language]}} 2
                    </label>
                    <div class="col-12">
                      <textarea v-model="editUserData.adress2" id="inputEditUserAdress2" rows="1"
                      class="form-control" style="color: black; padding: 0.7rem;"/>
                    </div>
                  </div>

                </div>

                <div class="row">

<!-- MODULOS -->
                  <div class="form-group col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Módulos permitidos'][session.user.language]}}
                    </label>
                    <div class="col-12 d-flex flex-wrap">
                      <div v-for="m in session.authorisation.availableModules" :key="m" 
                      class="btn modules-button w-auto mb-2"
                      :class="(editUserData.authorisation.availableModules[m] != undefined)?'active': ''"
                      @click="(editUserData.authorisation.availableModules[m] != undefined)? delete editUserData.authorisation.availableModules[m] : editUserData.authorisation.availableModules[m]=1">
                        {{ m.substring(0,1).toUpperCase()+m.substring(1,m.length) }}
                      </div>
                    </div>
                  </div>

                </div>

                <div class="col-12 p-0 ps-2 ms-1 pe-3">
                  <hr class="tx-clomos-dark">
                </div>

                <div class="row">

                  <div class="form-group col-md-6 col-12 p-1 pt-0">
                    <label class="col-12 tx-bold tx-clomos-dark">
                      {{dictionary['Preferencias'][session.user.language]}}:
                    </label>
                  </div>
                  
                </div>

                <div class="row">

<!-- SUBMIT -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Idioma'][session.user.language]}} *
                    </label>
                    <div class="col-md-12">
                      <select v-model="editUserData.language"
                      id="selectEditUserLanguage"
                      class="form-control form-control-line" style="color:black;">
                        <option value="es">
                          {{dictionary['Español'][session.user.language]}}
                        </option>
                        <option value="en">
                          {{dictionary['Inglés'][session.user.language]}}
                        </option>
                      </select>
                    </div>
                  </div>

<!-- SEPARADOR DE COMAS CSV -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Separador de comas CSV'][session.user.language]}}
                    </label>
                    <div class="col-sm-12">
                      <select v-model="editUserData.fieldSeparator"
                      id="slectEditUserFieldSeparator"
                      class="form-control form-control-line" style="color:black;">
                        <option value=",">
                          {{dictionary['Coma'][session.user.language]}} ","
                        </option>
                        <option value=";">
                          {{dictionary['Punto y coma'][session.user.language]}} ";"
                        </option>
                      </select>
                    </div>
                  </div>

                </div>

                <div class="row">

<!-- TEMPERATURA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Temperatura'][session.user.language]}}
                    </label>
                    <div class="col-sm-12">
                      <select v-model="editUserData.unitTemperature"
                      id="selectEditUserUnitTemperature"
                      class="form-control form-control-line" style="color:black;">
                        <option value="C">Cº (Celsius)</option>
                        <option value="F">Fº (Fahrenheit)</option>
                      </select>
                    </div>
                  </div>
                  
<!-- FORMATO -->
                  <div class="form-group col-md-6 col-12 p-1">
                      <label class="col-12 tx-bold tx-clomos-midtone">
                        {{dictionary['Formato'][session.user.language]}}
                      </label>
                      <div class="col-12">
                        <select v-model='editUserData.dateTimeFormat'
                        id="selectEditUserDateTimeFormat"
                        class="form-control form-control-line" style="color:black;">
                          <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                          <option value="MM/DD/YYYY">MM/DD/YYYY</option>
                        </select>
                      </div>
                    </div>

                </div>

                <div class="row">

<!-- ZONA HORARIA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Zona horaria'][session.user.language]}}
                    </label>
                    <div class="col-12">
                      <select v-model="editUserData.dateTimeZone"
                      id="selectEditUserDateTimeZone"
                      class="form-control form-control-line" style="color:black;">
                        <option v-for="timeZone in timeZones" :key="timeZone['value']" :value="timeZone['value']">
                          {{timeZone['value'].replace("_"," ")}}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group col-md-6 col-12 p-1">
                    <div class="col-12 mt-4">
<!-- SUBMIT -->
                      <button type="button" id="btnSubmitCreateEditUser" class="btn btn-clomos w-100"
                      @click="setEditUserData">
                        {{dictionary['Guardar cambios'][session.user.language]}}
                      </button>
                    </div>
                  </div>

                </div>

<!-- FORM My Profile (End) -->
                
              </div>
            </form>
              
          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "EditUserModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    userId: {
      type: String,
      requiered: true,
    },
  },
  data() {
    return {
      changePassData: {
        oldPassword: "",
        newPassword: "",
        newPasswordCheck: ""
      },
      editUserData: {
        id: "",
        name: "",
        lastName: "",
        email: "",
        emailRe: "",
        adress1: "",
        adress2: "",
        province: "",
        country: "",
        telephone: "",
        rol: "",
        language: "",
        unitTemperature: "",
        unitDistance: "",
        dateTimeZone: "",
        dateTimeFormat: "",
        fieldSeparator: "",
        authorisation: {
          availableModules: []
        }
      },
    }
  },
  emits: ["toggleEditUserModal"],
  methods: {
    // GET 
    async getEditUserData(callback) {
      let response = await api.getUserData(this.userId);
      this.editUserData = api.parseUserFromResponse(response);
      if (callback && typeof callback === "function") callback(this.editUserData);
    },
    // SET 
    async setEditUserData() {
      let valid = true;
      //if (this.editUserData.email != this.editUserData.emailRe
      //|| this.editUserData.email === ""
      //|| this.editUserData.emailRe === "") {
      //  $("#inputEditUserEmail").get()[0].classList.add("missing")
      //  $("#inputEditUserEmailRe").get()[0].classList.add("missing")
      //  valid = false;
      //}
      if (!valid) {
        setTimeout(() => {
          $("#inputEditUserEmail").get()[0].classList.remove("missing")
          $("#inputEditUserEmailRe").get()[0].classList.remove("missing")
          }, 3000)
          return;
      }
      let response = await api.setUserData(this.editUserData);
      console.log(response);
      if (response["status-code"] === "200") {
        window.alert("El usuario ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el usuario.");
      }
      this.getEditUserData();
    },
    // SET Password
    async onChangePassword() {
            let valid = true;
      if (this.changePassData.newPassword != this.changePassData.newPasswordCheck
      || this.changePassData.newPassword === ""
      || this.changePassData.newPasswordCheck === "") {
        $("#inputEditUserNewPassword").get()[0].classList.add("missing")
        $("#inputEditUserNewPasswordChe").get()[0].classList.add("missing")
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $("#inputEditUserNewPassword").get()[0].classList.remove("missing")
          $("#inputEditUserNewPasswordChe").get()[0].classList.remove("missing")
          }, 3000)
          return;
      }
      let request = {
        newPassword: this.changePassData.newPassword,
        userId: this.editUserData.id
      }
      let response = await api.setUserPasswordUnverified(request);
      if (response) {
        console.log("Password changed.");
        this.changePassData.oldPassword = "";
        this.changePassData.newPassword = "";
        this.changePassData.newPasswordCheck = "";
      }
    },
  },
  created() {
    this.getEditUserData();
  }
}
</script>

<style>

#editUserModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>