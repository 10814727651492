<template>
<!-- MODAL Edit Company -->
  <div id="editCompanyModal"
  class="col-12 justify-content-center pe-6"
  style="padding: 15vh 20%">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">
<!-- HEAD -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100">
        <div class="col-10">
          <h4 class="tx-left tx-clomos-white mb-2 ps-1 pt-2">
            <ion-icon name="briefcase-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary['Editar empresa'][session.user.language]+": "+editCompanyData.name}}
          </h4>
        </div>

        <div class="col-2 m-0 p-0" style="display: flex; justify-content: end;"
        @click="$emit('toggleEditCompanyModal')">	
          <ion-icon name="close" class="ms-auto tx-46 tx-white align-bottom"></ion-icon>
        </div>

      </div>

      <div class="col-12 p-0 w-100">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="card-body">

<!-- FORM Edit Company -->
            <form class="justify-content-center form-material border-1 p-3 pt-0 overflow-hidden">
              
              <div class="container-fluid col-12">
                <div class="row form-group">
                  <div class="col-12" style="text-align: left;">
                    <label class=" p-0 pt-3 tx-bold tx-clomos-midtone">
                      {{dictionary["NIF/CIF"][session.user.language]}} *
                    </label><br>
                    <input v-model="editCompanyData.nifCif" id="inputEditCompanyNifCif" type="text" disabled
                    class="form-control p-2 text-black">
                  </div>

<!-- NOMBRE -->
                  <div class="col-lg-4 col-md-12 col-12" style="text-align: left;">								
                    <label class=" p-0 pt-3 tx-bold tx-clomos-midtone">
                      {{dictionary["Nombre de la empresa"][session.user.language]}} *
                    </label><br>
                    <input v-model="editCompanyData.name" id="inputEditCompanyName" type="text"
                    class="form-control" style="color: black; padding: 0.65rem 0.75rem;">
                  </div>

<!-- CORREO -->
                  <div class="col-lg-4 col-md-12 col-12" style="text-align: left;">
                    <label class=" p-0 pt-3 tx-bold tx-clomos-midtone">
                      {{dictionary["E-mail de la empresa"][session.user.language]}} *
                    </label><br>
                    <input v-model="editCompanyData.email" id="inputEditCompanyEmail" type="email"
                    class="form-control" style="color: black; padding: 0.65rem 0.75rem;">
                  </div>

<!-- TELÉFONO -->
                  <div class="col-lg-4 col-md-12 col-12" style="text-align: left;">
                    <label class=" p-0 pt-3 tx-bold tx-clomos-midtone">
                      {{dictionary["Teléfono de la empresa"][session.user.language]}}
                    </label><br>
                    <div class="col-12 d-flex flex-row p-0">
                      <div class="w-100">
                        <input v-model="editCompanyData.telephone" id="inputEditCompanyTelephone" type="text"
                        class="form-control" style="color:black; padding: 0.65rem 0.75rem;">
                      </div>
                    </div>
                  </div>
                  
<!-- PAÍS -->
                  <div class="col-lg-4 col-md-12 col-12" style="text-align: left;">
                    <label class=" p-0 pt-3 tx-bold tx-clomos-midtone">
                      {{dictionary["País"][session.user.language]}}
                    </label><br>
                    <select v-model="editCompanyData.country" id="selectCountry"
                    class="form-control"  style="color: black; padding: 0.7rem;">
                      <option v-for="country in countries" :key="country['code']" :value="country['code']">
                        {{country['name'][session.user.language]}}
                      </option>
                    </select>
                  </div>

<!-- DIRECCIÓN -->
                  <div class="col-lg-8 col-md-12 col-12" style="text-align: left;">
                    <label class=" p-0 pt-3 tx-bold tx-clomos-midtone">
                      {{dictionary["Dirección"][session.user.language]}} *
                    </label><br>
                    <textarea v-model="editCompanyData.adress" id="inputEditCompanyAdress" name="adress" rows="1"
                    class="form-control" style="color: black; padding: 0.7rem;"/> 
                  </div>

<!-- SECTOR -->
                  <div class="col-lg-8 col-md-12 col-12" style="text-align: left;">								
                    <label class=" p-0 pt-3 tx-bold tx-clomos-midtone">
                      {{dictionary["Sector"][session.user.language]}}
                    </label><br>
                    <select v-model="editCompanyData.sector" id="selectEditCompanySector"
                    class="form-control" style="color:black; padding: 0.65rem 0.75rem;">
                      <option value="aeronautica">
                        {{dictionary["Aeronáutica"][session.user.language]}}
                      </option>
                      <option value="agroalimentario">
                        {{dictionary["Agroalimentario"][session.user.language]}}
                      </option>
                      <option value="automotriz">
                        {{dictionary["Automotriz"][session.user.language]}}
                      </option>
                      <option value="construccion">
                        {{dictionary["Obra civil"][session.user.language]}}
                      </option>
                      <option value="quimica">
                        {{dictionary["Química"][session.user.language]}}
                      </option>
                      <option value="militar">
                        {{dictionary["Defensa/Militar"][session.user.language]}}
                      </option>
                      <option value="electronica">
                        {{dictionary["Electrónica"][session.user.language]}}
                      </option>
                      <option value="energia">
                        {{dictionary["Energía"][session.user.language]}}
                      </option>
                      <option value="it">
                        {{dictionary["Tecnologías de la información"][session.user.language]}}
                      </option>
                      <option value="industria 4.0">
                        {{dictionary["Industria 4.0"][session.user.language]}}
                      </option>
                      <option value="farma-medico">
                        {{dictionary["Farma-médico"][session.user.language]}}
                      </option>
                      <option value="logistica">
                        {{dictionary["Transporte y logística"][session.user.language]}}
                      </option>
                      <option value="almacenamiento">
                        {{dictionary["Manejo y almacenamiento de materiales"][session.user.language]}}
                      </option>
                      <option value="canteras">
                        {{dictionary["Canteras, cemento, mármoles"][session.user.language]}}
                      </option>
                      <option value="papeleras">
                        {{dictionary["Fábricas de papel y celulosa"][session.user.language]}}
                      </option>
                      <option value="gas">
                        {{dictionary["Petróleo y gas"][session.user.language]}}
                      </option>
                      <option value="puertos">
                        {{dictionary["Industria Portuaria"][session.user.language]}}
                      </option>
                      <option value="puertos deportivos">
                        {{dictionary["Puertos deportivos"][session.user.language]}}
                      </option>
                      <option value="astilleros">
                        {{dictionary["Astilleros"][session.user.language]}}
                      </option>
                      <option value="telecomunicaciones">
                        {{dictionary["Telecomunicaciones"][session.user.language]}}
                      </option>
                      <option value="hierro y acero">
                        {{dictionary["Hierro y acero"][session.user.language]}}
                      </option>
                      <option value="reciclaje">
                        {{dictionary["Tratamiento de residuos/Reciclaje"][session.user.language]}}
                      </option>
                      <option value="otro">
                        {{dictionary["Otro"][session.user.language]}}
                      </option>
                    </select>
                  </div>

                  <div class="col-lg-4 col-md-12 col-12 pt-2 pb-1">								
                    <button type="button" @click="setEditCompanyData"
                    style="float:right; margin: 2rem auto auto auto; height: 3rem; width: 100%;" class="btn btn-clomos rounded">
                      {{dictionary["Guardar cambios"][session.user.language]}}
                    </button>
                  </div>

                </div>
              </div>
                
            </form>

<!-- FORM Company Logo-->
            <div class="col-12 pt-3 p-0 overflow-hidden">

              <div class="justify-content-center bg-clomos-midtone rounded-5">
                
                <label class=" col-12 p-3 pb-0 tx-white tx-bold text-nowrap">
                  {{dictionary["Logotipo de la empresa"][session.user.language].toUpperCase()}}
                </label>
                <label class=" col-12 p-3 pt-0 tx-white tx-light text-nowrap">
                  {{dictionary["Tamaño recomendado"][session.user.language]+': 200x200px'}}
                </label>
                
                <img id="logo" class="w-100" style="max-width: 100px; width: auto; padding: 0 1rem 1rem 1rem;" :src="displayCompanyImage(editCompanyData.logoSrc)">

                <div v-if="editCompanyImageForm.file!=''" class="myCompanyImagePreview w-100 p-3 m-auto">
                  <div class="d-flex flex-column">
                    <button type="button" title="Remove file" @click="onRemoveEditCompanyImage()" style="line-height: 0;" class="p-1 m-0 rounded ms-auto">
                      <ion-icon name="close" class="tx-18 tx-black align-bottom"></ion-icon>
                    </button>
                    <img style="max-width: 200px; width: auto; padding: 1rem; margin: auto;" :src="displayEditCompanyThumbnail(editCompanyImageForm.file)" />
                  </div>
                </div>

                <div id="logoUploadFormContainer" class="w-100 p-3 pt-0" style="display: flex; justify-content: space-around; gap: 1rem;">

                  <input type="file" id="logoUploadSrc" class="w-100 file-button btn btn-secondary rounded" style="height: 3rem; min-width: 180px;"
                  @change="onChangeEditCompanyImage" accept=".png">

                  <button type="button" id="logoUploadSubmit" class=" w-100 file-button btn btn-dark rounded" style="height: 3rem; min-width: 180px;"
                  @click="setEditCompanyImage">
                    <ion-icon name="log-in-outline" class="me-3"></ion-icon>
                    {{dictionary["Guardar archivo"][session.user.language]}}
                  </button>

                </div>

              </div>

            </div>

          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "EditCompanyModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    companyId: {
      type: String,
      requiered: true,
    },
  },
  data() {
    return {
      updated: false,
      editCompanyData: {
        nifCif: "",
        name: "",
        email: "",
        telephone: "",
        country: "",
        adress: "",
        sector: "",
        logoSrc: "",
      },
      editCompanyImageForm: {
        nifCif: "",
        src: "",
        file: ""
      }
    }
  },
  emits: ["toggleEditCompanyModal"],
  methods: {
    // DISPLAY
    displayCompanyImage(src) {
      if (src!=undefined && src!='') {
        try {
          return this.$clientConfig.api + '/ficheros/ficheros.php?fichero='+src+'&identificadorusuario='+this.session.user.id+'&Token='+localStorage.getItem("ClomosAuthToken");
        }
        catch(e) { 
          console.warn(e);
        }
      }
      return require('/src/assets/target/img/logo-sm.png');
    },
    displayEditCompanyThumbnail(file) {
      if (file === '' || file === undefined) return "";
      let fileSrc = URL.createObjectURL(file);
        setTimeout(() => {
            URL.revokeObjectURL(fileSrc);
        }, 1000);
      return fileSrc;
    },
    onChangeEditCompanyImage(event) {
      if (event.target.files[0] != undefined && event.target.files[0].size > 2097152) {
        alert("El tamaño del fichero debe ser inferior a 2Mb.");
        return;
      }
      this.editCompanyImageForm.nifCif = this.editCompanyData.nifCif;
      this.editCompanyImageForm.file = event.target.files[0];
      this.editCompanyImageForm.src = event.target.files[0].name;
    },
    onRemoveEditCompanyImage() {
      $("#logoUploadSrc").get()[0].value="";
      this.editCompanyImageForm.nifCif = "";
      this.editCompanyImageForm.file = "";
      this.editCompanyImageForm.src = "";
    },
    // GET
    async getEditCompanyData(callback) {
      let response = await api.getCompanyData(this.companyId);
      this.editCompanyData = api.parseCompanyFromResponse(response);
      if (callback && typeof callback === "function") callback(this.editCompanyData);
    },
    // SET
    async setEditCompanyData() {
      let response = await api.setCompanyData(this.editCompanyData);
      console.log(response);
      if (response["status-code"] === "200") {
        window.alert("La empresa ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar la empresa.");
      }
      this.getEditCompanyData();
    },
    async setEditCompanyImage() {
      let response = await api.setCompanyImage(this.editCompanyImageForm);
      console.log(response);
      this.onRemoveEditCompanyImage();
      this.getEditCompanyData();
    }
  },
  created() {
    this.getEditCompanyData();
  },
}
</script>

<style>
#editCompanyModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.myCompanyImagePreview {
  max-width: 100%;
  margin: auto; 
}

@media (max-width: 767px) {
  #logoUploadFormContainer {
    flex-direction: column;
  }
}
</style>