<template>
<!-- SECTION Orders-->
  <div id="ordersSection" class="col-12 justify-content-center pe-3"
  :class="(visibility.sideMenu)?'section-container-sm' :'section-container-lg'">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">


<!-- HEADER Orders -->

      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100" >

        <div class="col-lg-9 col-12 justify-content-center" style="display: inherit">
          <!--<ion-icon v-if="session.user.bookmark==='orders'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <h4 class="tx-left tx-clomos-white text-nowrap mb-2 ps-1 pt-2" style="height:40px;">

            <ion-icon name="basket-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary["Pedidos"][session.user.language]}}
          </h4>
        </div>

        <div class="col-lg-3 col-12 p-2 pb-1">	
          <button id="btnDisplayNewExpeditionModal"
          class="btn btn-clomos bg-white rounded p-1 inner-border fw-normal text-nowrap"
          style="width: 100%; height:100%; vertical-align: middle; border: solid 2px white"
          @click="toggleNewExpeditionModal">
              <ion-icon name="add-circle-outline" class="tx-clomos-dark me-2 tx-20" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
              <span class="ms-auto tx-clomos-dark">{{dictionary["Salida de productos"][session.user.language]}}</span>
          </button>
        </div>
      </div>

      <div class="m-0 p-3 overflow-auto">
        <table id="ordersDataTable" class="display w-100 border-1 p-0 m-0 rounded-3">
          <thead class="bg-clomos-light">
            <tr>
              <th v-for="column in ordersColumns" :key="column.data"
              :width="column.width" class="tx-black fw-medium text-nowrap">
              {{dictionary[column.text][session.user.language]}}          
             </th>
            </tr>
          </thead>
        </table>
      </div>

    </div>
  </div>
<!-- MODAL Order Details -->
  <OrderDetailsModal
  v-if="display.orderDetailsModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  :orderId="targetOrderId"
  v-on:toggleOrderDetailsModal="toggleOrderDetailsModal">
  </OrderDetailsModal> 

<!-- MODAL New Expedition -->
  <NewExpeditionModal
  v-if="display.newExpeditionModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  :hasReceipt="false"
  v-on:toggleNewExpeditionModal="toggleNewExpeditionModal">
  </NewExpeditionModal> 
</template>

<script>
import $ from 'jquery';
import OrderDetailsModal from '@/components/orders/OrderDetailsModal.vue';
import NewExpeditionModal from '@/components/expeditions/NewExpeditionModal.vue';
// Clomos
import api from '@/services/api';

export default {
  name: "OrdersComponent",
  components: {OrderDetailsModal, NewExpeditionModal},
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      await this.getOrderList();
      this.displayOrdersDataTable();
    }
  },
  data() {
    return {
      ordersColumns: [
      {text:"Pedido" , data: "orderIdPedidoMarket", width: "25%"},
        {text:"Producto", data: "orderIdPedido", width: "25%",
          render: (data) => {
            return "<ion-icon name='list-circle-outline' data-id="+data+" class='btnDetailOrder btn tx-24 tx-primary  p-0 m-0'></ion-icon>";
          }
        },
        {text:"Fecha de creación", data: "orderFecha", width: "25%",
          render: (data) => {   
            return this.parseDateUserTimezone(data);
          }
        },
        {text:"Tipo de transporte", data: "orderTipoTransporte", width: "25%",
          render: (data) => {
            switch (data) {
              case 1:
                return "Ultima milla";
              case 2:
                return "media milla";
              default:
                return "-";
            }
          }
        },
        {text:"Detalles de pedido", data: "orderIdPedido", width: "10%",
          render: (data) => {
            return "<ion-icon name='chevron-forward-circle-outline' data-id="+data+" class='btnOpenDetailModal btn tx-24 tx-primary  p-0 m-0'></ion-icon>";
          }
        },
      ],
      targetOrderId: "",
      orderList: [],
      bulgeInput: {
        volume: {
          height: 0,
          width: 0,
          depth: 0
        },
        units: 0,
      },
      bulgeList: [],
      display: {
        orderDetailsModal: false,      }
    }
  },
  computed: {
    targetOrder() {
      for (let i in this.orderList) {
        if (this.orderList[i].id === this.targetOrderId)
          return this.orderList[i];
        }
      return {};
    },
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    parseDateUserTimezone(date) {
      let formatedDate = "-";
      let parsedDate = (date!="")
        ?this.$dayjs(new Date(date)).utc("z").local().tz(this.session.user.dateTimeZone).$d
        :""; 
      if (parsedDate!="") {
        let d = new Date(parsedDate);
        switch (this.session.user.dateTimeFormat) {
          case "DD/MM/YYYY": 
            formatedDate = String(d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear()+" "+d.getHours()).padStart(2,"0")+":"+String(d.getMinutes()).padStart(2,"0");
            break;
          case "MM/DD/YYYY": 
            formatedDate = String((d.getMonth()+1)+"/"+d.getDate()+"/"+d.getFullYear()+" "+d.getHours()).padStart(2,"0")+":"+String(d.getMinutes()).padStart(2,"0");
            break;
          default:
            formatedDate = String((d.getMonth()+1)+"/"+d.getDate()+"/"+d.getFullYear()+" "+d.getHours()).padStart(2,"0")+":"+String(d.getMinutes()).padStart(2,"0");
            break;
        }
      }
      return formatedDate;
    },
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    displayOrdersDataTable() {
      $("#ordersDataTable").DataTable().clear()
      for (let i in this.orderList) {
          if (this.orderList[i].summary != undefined && this.orderList[i].summary != "") 
       {
          let resumenParseado = JSON.parse(this.orderList[i].summary) 
          $("#ordersDataTable").DataTable().rows.add([{
          orderIdPedido: this.orderList[i].id, 
          orderIdPedidoMarket: resumenParseado.idPedido, 
          orderFecha: this.orderList[i].creationDateTime,
          orderTipoTransporte: (resumenParseado.tipoTrasporte !== undefined) ? resumenParseado.tipoTrasporte : "-", 
        }
      ]);
      }}
      $("#ordersDataTable").DataTable().draw();
      this.setButtonFunctions();
     if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
    },
    displayExpeditionsDataTable() {
      $("#expeditionsDataTable").DataTable().clear()
      for (let i in this.expeditionList) {
        $("#expeditionsDataTable").DataTable().rows.add([{
          expeditionId: this.expeditionList[i].id, 
        }]);
      }
      $("#expeditionsDataTable").DataTable().draw();
      this.setButtonFunctions();
     if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
    },
    async toggleOrderDetailsModal() {
      if (this.display.orderDetailsModal) {
        await this.getOrderList();
        this.displayOrdersDataTable();
      }
      this.display.orderDetailsModal = !this.display.orderDetailsModal;
    },

    async toggleNewExpeditionModal() {
      if (this.display.newExpeditionModal) {
        await this.getExpeditionList();
        this.displayExpeditionsDataTable();
      }
      this.display.newExpeditionModal = !this.display.newExpeditionModal;
    },
    // BUTTONS
    setButtonFunctions() {
      $(".btnTargetOrder").get().forEach(btn => {
        btn.addEventListener('click', this.onTargetOrder);
      });
      $(".btnDetailOrder").get().forEach(btn => {
        btn.addEventListener('click', this.onDetailOrder)
      });
      $(".btnOpenDetailModal").get().forEach(btn => {
        btn.addEventListener('click', this.onOpenDetailModal);
      });
      
    },
    // GET
    async getOrderList(callback) {
      let request = {
        userId: this.session.user.id
      };
      let response = await api.getOrderList(request);
      console.log(response)
      this.orderList = api.parseOrdersFromResponse(response);
      
      if (callback && typeof callback === "function") callback(this.orderList);
    },

    async getExpeditionList(callback) {
      let request = {
        userId: this.session.user.id
      };
      let withdrawListResponse = await api.getExpeditionList(request);
      console.log(withdrawListResponse)
      let withdrawList = api.parseOrdersFromResponse(withdrawListResponse)
      let orderListResponse = await api.getOrderList(request);
      console.log(orderListResponse)
      let orderList = api.parseOrdersFromResponse(orderListResponse);

      this.expeditionList = withdrawList.concat(orderList);

      if (callback && typeof callback === "function") callback(this.expeditionList);
    },
    // TARGET 
    onTargetOrder(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetOrderId = event.target.getAttribute('data-id');
    },
    // DETAILS
    onDetailOrder(event) {
      console.log('DETAIL WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetOrderId = event.target.getAttribute('data-id')
      var tr = $(event.target).closest('tr');
      var row = $("#ordersDataTable").DataTable().row(tr);
      if (row.child.isShown()) {
        event.target.name = "list-circle-outline";
        row.child.hide();
        tr.removeClass('shown');
      } 
      else {
        event.target.name = "chevron-up-circle-outline";
        let content = '' 
        content = content +
          '<table id="detailTable'+this.targetOrderId+'" cellspacing="0" border="0" style="width:100%; margin: 0;">' +
            '<tr>' +
              '<td class="tx-bold">Id Producto</td>' +
              '<td class="tx-bold">Producto</td>' +
              '<td class="tx-bold">Unidades</td>' +
            '</tr>';
          for (let i in this.targetOrder.params.productos) {
            let product = this.targetOrder.params.productos[i];
            content = content + '<tr>';
            
              if (i !=undefined) content = content + '<td>' + i + '</td>'
              else content = content + '<td>-</td>';
              if (product.etiqueta!=undefined) content = content + '<td>' + product.etiqueta + '</td>'
              else content = content + '<td>-</td>';
              if (product.unidades!=undefined) content = content + '<td>' + product.unidades + '</td>'
              else content = content + '<td>-</td>';
            '</tr>';
          }
          content = content + '</table>';
        row.child(content).show();
        document.getElementById('detailTable'+this.targetOrderId).parentNode.style.padding = 0;
        tr.addClass('shown');
      }
    },

    onOpenDetailModal(event){
      console.log('OPEN MODAL WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetOrderId = event.target.getAttribute('data-id');
      this.toggleOrderDetailsModal();
    },
  },
  async created() {
    await this.getOrderList();
      this.displayOrdersDataTable();
  },
  mounted() {
    $("#ordersDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.ordersColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
  }
}
</script>
<style>
#orderDetailsModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.btnDetailOrder:hover {
  color: var(--main-dark);
}

</style>