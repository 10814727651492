<template>
<!-- SECTION Emplacements -->
  <EmplacementsComponent
  :session="session"
  :dictionary="dictionary"
  :visibility="visibility"
  :availableCompanies="availableCompanies"
  :currentCompany="targetCompany"
  :targetCompany="currentCompany"
  :dataTableOptions="dataTableOptions">
  </EmplacementsComponent>

</template>

<script>
import EmplacementsComponent from '@/components/emplacements/EmplacementsComponent';

export default {
  name: "DashboardComponent",
  components: { EmplacementsComponent },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: function() {
          this.targetCompany = this.currentCompany
    }
  },
  data() {
    return {
      targetCompany : {}
    }
  },
  created() {
    this.targetCompany = this.currentCompany
  }
}
</script>

<style>
@media (max-width: 1200px) {
  .siteCard {
    padding-right: 1rem !important;
  }
}
</style>