<template>
<!-- MODAL Edit Stock -->
  <div id="regularizeStockModal"
  class="col-12 justify-content-center pe-6"
  style="padding: 15vh 20%">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">

<!-- HEAD Edit Stock -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100">
        <div class="col-10">
          <h4 class="tx-left tx-clomos-white mb-2 ps-1 pt-2">
            <ion-icon name="cube-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary['Regularizar stock'][session.user.language]}}
          </h4>
        </div>

        <div class="col-2 m-0 p-0" style="display: flex; justify-content: end;"
        @click="$emit('toggleRegularizeStockModal')">	
          <ion-icon name="close" class="ms-auto tx-46 tx-white align-bottom"></ion-icon>
        </div>
      </div>

      <div class="col-12 p-0 w-100">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="card-body">

<!-- CONTENT Edit Stock -->
            <form class="form-material border-1 p-3" style="overflow: scroll; overflow-x: auto; overflow-y: hidden;">
                
                <div class="row pb-3">
                  
<!-- NOMBRE -->
                  <div class="form-group col-md-6 col-12">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Referencia'][session.user.language]}}:
                    </label>
                    <div class="col-md-12">
                      <p id="inputRegularizeStockName" class="m-0 p-0 tx-13"> {{stockData.reference}} </p>
                      <div class="col-12 p-0">
                        <hr class="tx-clomos-dark m-0">
                      </div>
                    </div>
                  </div>
                  
                  <div class="form-group col-md-6 col-12">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Nombre'][session.user.language]}}:
                    </label>
                    <div class="col-md-12">
                      <p id="inputRegularizeStockName" class="m-0 p-0 tx-13"> {{stockData.name}} </p>
                      <div class="col-12 p-0">
                        <hr class="tx-clomos-dark m-0">
                      </div>
                    </div>
                  </div>
                </div>

<!-- LISTA -->
                <div class="row col-12 m-auto border-1 rounded-3 mb-3 p-0" style="min-width: 750px">
                  <table id="stocksTable" class="display col-12 p-0 m-0">
                    <thead class="bg-clomos-portada border-bottom">
                      <tr>
                        <th class="tx-black fw-medium text-nowrap w-20">
                          {{dictionary['Talla'][session.user.language]}}
                        </th>
                        <th class="tx-black fw-medium text-nowrap w-20">
                          {{dictionary['Color'][session.user.language]}}
                        </th>
                        <th class="tx-black fw-medium text-nowrap w-20">
                          {{dictionary['Fecha de caducidad'][session.user.language]}}
                        </th>
                        <th class="tx-black fw-medium text-nowrap w-20">
                          {{dictionary['Stock actual'][session.user.language]}}
                        </th>
                        <th class="tx-black fw-medium text-nowrap w-20">
                          {{dictionary['Nuevo stock'][session.user.language]}}
                        </th>
                      </tr>
                    </thead>
                    <tr v-for="stock in stockData.stockList" :key="stock" class="border-top">
                      <td>
                        {{stock.size}}
                      </td>
                      <td>
                        {{stock.color}}
                      </td>
                      <td>
                        {{stock.expirationDate}}
                      </td>
                      <td>
                        {{stock.amount}}
                      </td>
                      <td class="d-flex p-2">
                        <input v-model="stock.newAmount" id="inputRegularizeStockNewAmount" type="number"
                        class="form-control text-center w-75 p-2 align-right" style="color:black;">
                        <ion-icon name='create-outline' :data-id="stock.id" class='btnRegularizeStock btn tx-24 w-25 tx-primary p-0 ms-2 m-auto'></ion-icon>
                      </td>
                    </tr>
                  </table>
                  
                </div>

            </form>
              
          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
// Clomos
import api from '@/services/api';

export default {
  name: "RegularizeStockModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    productId: {
      type: String,
      requiered: true,
    },
  },
  data() {
    return {
      stockData: {
        nifCif: this.currentCompany.nifCif,
        id: "",
        reference: "",
        name: "",
        stockList: []
      },
      orderHistory: [],
    }
  },
  emits: ["toggleRegularizeStockModal"],
  methods: {
    // GET 
    async getRegularizeStockData(callback) {
      this.stockData.name = "ZAPATILLAS",
      this.stockData.reference = "ZP-003",
      this.stockData.stockList = [
        { 
          color: "Verde",
          size: "L",
          expirationDate: "-",
          amount: 10,
          newAmount: 10
        },
        { 
          color: "Verde",
          size: "XL",
          expirationDate: "-",
          amount: 15,
          newAmount: 15
        },
        { 
          color: "Azul",
          size: "XXL",
          expirationDate: "-",
          amount: 20,
          newAmount: 20
        }
      ]
      //let response = await api.getStockData(this.productId);
      //this.stockData = api.parseStockFromResponse(response);
      if (callback && typeof callback === "function") callback(this.stockData);
    },
    // SET
    async regularizeStockData() {
      let response = await api.setStockData(this.stockData);
      console.log(response);
      if (response["status-code"] === "200") {
        this.$emit('toggleRegularizeStockModal');
        window.alert("El stock ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el stock.");
      }
      this.getRegularizeStockData();
    },
  },
  created() {
    this.getRegularizeStockData();
  }
}
</script>

<style>

#regularizeStockModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

@media (max-width: 767px) {
  #stockImgUploadFormContainer {
    flex-direction: column;
  }
}
</style>