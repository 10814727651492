<template>
<!-- SECTION Stock-->
  <div id="stocksSection" class="col-12 justify-content-center pe-3"
  :class="(visibility.sideMenu)?'section-container-sm' :'section-container-lg'">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">
  
    <!-- HEADER Stock -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100" >

        <div class="col-lg-6 col-md-12 col-12 justify-content-center" style="display: inherit">
          <!--<ion-icon v-if="session.user.bookmark==='stock'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->

          <h4 class="tx-left tx-clomos-white text-nowrap mb-2 ps-1 pt-2" style="height:40px;">
            <ion-icon name="layers-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary["Stock"][session.user.language]}}
          </h4>
        </div>

        <div class="col-lg-3 col-md-12 col-12 m-0 p-0"></div>

        <div class="col-lg-3 col-md-12 col-12 p-2 pb-1 justify-content-center">	
          <select
          :value="currentCompany.nifCif"
          class="form-control h-100 text-nowrap bg-white" 
          style="border: solid 2px white ; color: black;"
          @change="onCurrentCompanyChanged">
            <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
            <option v-for="company in availableCompanies" :key="company.nifCif"
            :v-if="company.nifCif != session.user.nifCif
            && company.relation != undefined
            && company.relation.referenceCompany === this.session.user.nifCif"
            :value="company.nifCif">
              {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
            </option>
          </select>
        </div>

      </div>

      <div class="d-flex flex-wrap m-0 p-3 overflow-auto">

    <!-- KPI Stock -->
        <div class="kpi-container col-lg-3 col-5 m-0">
          
          <div class="kpi w-100 col-4 p-3">
            <span> Total </span>
            <hr class="tx-clomos-dark">
            <span class="tx-bold tx-24"> 735 </span>
          </div>

          <div class="kpi w-100 col-4 p-3">
            <span> Salidas </span>
            <hr class="tx-clomos-dark">
            <span class="tx-bold tx-24"> 127 </span>
          </div>

          <div class="kpi w-100 col-4 p-3">
            <span> Entradas </span>
            <hr class="tx-clomos-dark">
            <span class="tx-bold tx-24"> 608 </span>
          </div>

        </div>

        <div class="col-lg-9 col-12 m-0 p-0">
          <table id="stocksDataTable" class="display w-100 border-1 p-0 m-0 rounded-3">
            <thead class="bg-clomos-light">
              <tr>
                <th v-for="column in usersColumns" :key="column.data"
                :width="column.width" class="tx-black fw-medium text-nowrap">
                  {{dictionary[column.text][session.user.language]}}
                </th>
              </tr>
            </thead>
          </table>
        </div>
      </div>

    </div>
  </div>

<!-- MODAL Edit Stock -->
  <RegularizeStockModal
  v-if="display.regularizeStockModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :stockId="targetStockId"
  :availableCompanies="availableCompanies"
  v-on:toggleRegularizeStockModal="toggleRegularizeStockModal">
  </RegularizeStockModal>

<!-- MODAL Edit Stock -->
  <StockModal
  v-if="display.stockOrdersModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :stockId="targetStockId"
  :availableCompanies="availableCompanies"
  v-on:toggleStockModal="toggleStockModal">
  </StockModal>

</template>

<script>
import $ from 'jquery';
import RegularizeStockModal from '@/components/stock/RegularizeStockModal';
import StockModal from '@/components/stock/StockModal';
// Clomos
//import api from '@/services/api';

export default {
  name: "StockComponent",
  components: { RegularizeStockModal, StockModal },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      await this.getStockList()
      this.displayStockDataTable();
    }
  },
  data() {
    return {
      usersColumns: [
        {text:"Referencia", data: "stockRef", width: "20%",
        render: (data) => {
            return "<span data-id="+data+" class='btnTargetStock "+/*"btn "+*/"tx-bold tx-clomos-midtone text-nowrap'>"+data+"</span>"
          }
        },
        {text:"Nombre", data: "stockName", width: "20%"},
        {text:"Empresa", data: "stockCompany", width: "20%",
          render: (data) => {
            let companyName = data;
            if (this.availableCompanies.length > 0) {
              for (let company in this.availableCompanies) 
                if (this.availableCompanies[company].nifCif === data ) 
                  companyName = this.availableCompanies[company].name;
            } 
            return companyName;
          }
        },
        {text:"Conservación", data: "stockConservationTemperature", width: "20%",
          render: (data) => {
            let conservationValue = "";
            switch(data) {
              case "frio": 
                conservationValue = this.dictionary["Frío"][this.session.user.language]
                break;
              case "ambiente": 
                conservationValue = this.dictionary["Ambiente"][this.session.user.language]
                break;
              case "ultracongelado": 
                conservationValue = this.dictionary["Ultracongelado"][this.session.user.language]
                break;
              default:
                break;
            }
            return conservationValue;
          }
        },
        {text:"Existencias", data: "stockAmount", width: "10%"},
        {text:"Regularizar", data: "stockId", width: "5%",
          render: (data) => {
            return "<ion-icon name='create-outline' data-id="+data+" class='btnRegularizeStock btn tx-24 tx-primary p-0 m-0'></ion-icon>";
          }
        },
        {text:"Gráficas", data: "stockId", width: "5%",
          render: (data) => {
            return "<ion-icon name='stats-chart-outline' data-id="+data+" class='btnDisplayStockOrders btn tx-24 tx-primary p-0 m-0'></ion-icon>";
          }
        }
      ],
      targetStockId: this.$route.params.targetEntity,
      stockList: [],
      display: {
        regularizeStockModal: false,
        stockOrdersModal: false,
        deleteStockModal: false
      }
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    displayStockDataTable() {
      $("#stocksDataTable").DataTable().clear()
      for (let i in this.stockList) {
        $("#stocksDataTable").DataTable().rows.add([{
          stockId: this.stockList[i].stockId,
          stockRef: this.stockList[i].stockRef,
          stockName: this.stockList[i].stockName,
          stockCompany: this.stockList[i].stockCompany,
          stockAmount: this.stockList[i].stockAmount,
          stockConservationTemperature: this.stockList[i].stockConservationTemperature,
          stockPosition: this.stockList[i].stockPosition
        }]);
      }
      $("#stocksDataTable").DataTable().draw();
      this.setButtonFunctions();
     if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
    },
    async toggleRegularizeStockModal() {
      if (this.display.regularizeStockModal) {
        await this.getStockList();
        this.displayStockDataTable();
      }
      this.display.regularizeStockModal = !this.display.regularizeStockModal;
    },
    async toggleStockModal() {
      if (this.display.stockOrdersModal) {
        await this.getStockList();
        this.displayStockDataTable();
      }
      this.display.stockOrdersModal = !this.display.stockOrdersModal;
    },
    // BUTTONS
    setButtonFunctions() {
      $(".btnTargetStock").get().forEach(btn => {
        btn.addEventListener('click', this.onTargetStock);
      });
      $(".btnRegularizeStock").get().forEach(btn => {
        btn.addEventListener('click', this.onRegularizeStock);
      });
      $(".btnDisplayStockOrders").get().forEach(btn => {
        btn.addEventListener('click', this.toggleStockModal);
      });
    },
    // GET
    async getStockList(callback) {
      //let request = {
      //  nifCif: this.currentCompany.nifCif,
      //  limit: 0,
      //  offset: 0,  
      //  where: "",
      //};
      //let response = await api.getStockList(request);
      //this.stockList = api.parseStockFromResponse(response);
      this.stockList = [
        {
          stockId: "001",
          stockRef: "100-A",
          stockName: "ZAPATOS",
          stockCompany: "DSI",
          stockConservationTemperature: "frio",
          stockAmount: "100",
        },
        {
          stockId: "002",
          stockRef: "200-A",
          stockName: "ZAPATILLAS",
          stockCompany: "DSI",
          stockConservationTemperature: "ambiente",
          stockAmount: "200",
        },
        {
          stockId: "003",
          stockRef: "300-A",
          stockName: "ZAPATAS",
          stockCompany: "DSI",
          stockConservationTemperature: "ultracongelado",
          stockAmount: "300",
        },
      ]
      if (callback && typeof callback === "function") callback(this.stockList);
    },
    // TARGET 
    onTargetStock(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetStockId = event.target.getAttribute('data-id')
        this.$router.replace({params: {targetEntity: this.targetStockId}})
    },
    // SET
    onRegularizeStock(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetStockId = event.target.getAttribute('data-id');
        this.$router.replace({params: {targetEntity: this.targetStockId}})
      this.toggleRegularizeStockModal();
    }
  },
  async created() {
    await this.getStockList()
    this.$emit('getAvailableCompanies',(this.displayStockDataTable));
  },
  mounted() {
    $("#stocksDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.usersColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
  }
}
</script>

<style>
/*.btnTargetStock:hover,*/ .btnChartsStock:hover, .btnRegularizeStock:hover, .btnDisplayStockOrders:hover {
  color: var(--main-dark);
}
.kpi-container {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem 2rem 1rem;
  gap: 1rem;
}
@media (max-width: 991px) {
  .kpi-container {
    display: flex;
    flex-direction: row;
    padding: 0 0 1rem 0;
    gap: 1rem;
  }
}
</style>